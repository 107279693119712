import React, { useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import Home from "../Components/pages/Home";
import About from "../Components/pages/About";
import Blog from "../Components/pages/Blog";
import BlogDetails from "../Components/pages/BlogDetails";
import CarDetails from "../Components/pages/CarDetails";
import CarListing from "../Components/pages/CarListing";
import NotFound from "../Components/pages/NotFound";
import Services from "../Components/pages/Services";
import Contact from "../Components/pages/Contact";
import Franchisee from "../Components/pages/Franchisee";
import Privacy from "../Components/pages/Privacy";
import Term from "../Components/pages/Term";
import Refund from "../Components/pages/Refund";
import Products from "../Components/pages/Products";
import PaymentSuccess from "../Components/pages/PaymentSuccess";
import PaymentFailure from "../Components/pages/PaymentFailure";
import PaymentStatus from "../Components/pages/PaymentStatus";
import RegisterComponent from "../Components/franchise/RegisterComponent";
import ProductDetailsPage from "../Components/UI/ServiceSection/ProductDetailsPage";
import ProductListingPage from "../Components/pages/ProductListingPage";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
import Navbar from "../Components/Header/Navbar";
import FloatingButton from "../Components/Header/FloatingButton";
import { Box } from "@chakra-ui/react";
import { useDispatch } from "react-redux";
import { fetchUserReviews } from "../redux/reviewsSlice";
import TimeSlotPicker from "../Components/UI/TimeSlotPicker";
import AddressForm from "../Components/UI/AddressForm";
import CartSummaryPage from "../Components/UI/CartSummaryPage";
// import { fetchReviews } from "../redux/reviewsSlice";
// import PaymentStatus from "../Components/pages/PaymentStatus"; // Import the new component

const Routers = () => {
 return (
  <Box>
   {/* <Header /> */}
   <Routes>
    <Route path="/" element={<Home />} />
    <Route path="/about" element={<About />} />
    <Route path="/cars" element={<CarListing />} />
    <Route path="/cars/:slug" element={<CarDetails />} />
    <Route path="/blogs" element={<Blog />} />
    <Route path="/blogs/:slug" element={<BlogDetails />} />
    <Route path="/service" element={<Services />} />
    <Route path="/product" element={<Products />} />
    <Route path="/product-listing-page" element={<ProductListingPage />} />
    <Route path="/slot-booking" element={<TimeSlotPicker />} />
    <Route path="/cart-summary" element={<CartSummaryPage />} />
    <Route path="/address" element={<AddressForm />} />
    <Route path="/product/:id" element={<ProductDetailsPage />} />
    <Route path="/contact" element={<Contact />} />
    <Route path="/register" element={<RegisterComponent />} />
    <Route path="/Franchisee" element={<Franchisee />} />
    <Route path="/Privacy" element={<Privacy />} />
    <Route path="/Term" element={<Term />} />
    <Route path="/Refund" element={<Refund />} />
    <Route path="/status" element={<PaymentStatus />} /> {/* Updated Route */}
    <Route path="/payment-success" element={<PaymentSuccess />} />
    <Route path="/payment-failure" element={<PaymentFailure />} />
    <Route path="*" element={<NotFound />} />
   </Routes>
   <Box display={{ base: "none", md: "block" }}>
    <FloatingButton />
   </Box>
  </Box>
 );
};

export default Routers;
