import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// API Endpoints
const partnerReviewsAPI =
 "https://app-api.carexpert.org.in/api/partner-reviews";
const userReviewsAPI = "https://app-api.carexpert.org.in/api/reviews";
const videoTestimonialsAPI =
 "https://app-api.carexpert.org.in/api/testimonial-videos";

// Thunks for Fetching
export const fetchPartnerReviews = createAsyncThunk(
 "reviews/fetchPartnerReviews",
 async (_, { rejectWithValue }) => {
  try {
   const response = await axios.get(partnerReviewsAPI);
   return response.data;
  } catch (error) {
   return rejectWithValue(error.response.data);
  }
 }
);

export const fetchUserReviews = createAsyncThunk(
 "reviews/fetchUserReviews",
 async (_, { rejectWithValue }) => {
  try {
   const response = await axios.get(userReviewsAPI);
   return response.data;
  } catch (error) {
   return rejectWithValue(error.response.data);
  }
 }
);

export const fetchVideoTestimonials = createAsyncThunk(
 "reviews/fetchVideoTestimonials",
 async (_, { rejectWithValue }) => {
  try {
   const response = await axios.get(videoTestimonialsAPI);
   return response.data;
  } catch (error) {
   return rejectWithValue(error.response.data);
  }
 }
);

// Thunks for Adding
export const addReview = createAsyncThunk(
 "reviews/addReview",
 async ({ type, data }, { rejectWithValue }) => {
  const apiEndpoint =
   type === "partner"
    ? partnerReviewsAPI
    : type === "user"
    ? userReviewsAPI
    : videoTestimonialsAPI;

  try {
   const response = await axios.post(apiEndpoint, data);
   return { type, data: response.data };
  } catch (error) {
   return rejectWithValue(error.response.data);
  }
 }
);

// Thunks for Deleting
export const deleteReview = createAsyncThunk(
 "reviews/deleteReview",
 async ({ type, id }, { rejectWithValue }) => {
  const apiEndpoint =
   type === "partner"
    ? `${partnerReviewsAPI}/${id}`
    : type === "user"
    ? `${userReviewsAPI}/${id}`
    : `${videoTestimonialsAPI}/${id}`;

  try {
   await axios.delete(apiEndpoint);
   return { type, id };
  } catch (error) {
   return rejectWithValue(error.response.data);
  }
 }
);
export const fetchAllReviews = createAsyncThunk(
 "reviews/fetchAllReviews",
 async (_, { dispatch, rejectWithValue }) => {
  try {
   // Fetch all reviews in parallel
   const [partnerReviews, userReviews, videoTestimonials] = await Promise.all([
    dispatch(fetchPartnerReviews()).unwrap(),
    dispatch(fetchUserReviews()).unwrap(),
    dispatch(fetchVideoTestimonials()).unwrap(),
   ]);

   return { partnerReviews, userReviews, videoTestimonials };
  } catch (error) {
   return rejectWithValue(error);
  }
 }
);

const reviewsSlice = createSlice({
 name: "reviews",
 initialState: {
  partnerReviews: [],
  userReviews: [],
  videoTestimonials: [],
  loading: false,
  error: null,
 },
 reducers: {},
 //  extraReducers: (builder) => {
 //   // Fetching
 //   builder.addCase(fetchPartnerReviews.fulfilled, (state, action) => {
 //    state.partnerReviews = action.payload;
 //    state.loading = false;
 //   });
 //   builder.addCase(fetchUserReviews.fulfilled, (state, action) => {
 //    state.userReviews = action.payload;
 //    state.loading = false;
 //   });
 //   builder.addCase(fetchVideoTestimonials.fulfilled, (state, action) => {
 //    state.videoTestimonials = action.payload;
 //    state.loading = false;
 //   });

 //   // Adding
 //   builder.addCase(addReview.fulfilled, (state, action) => {
 //    const { type, data } = action.payload;
 //    if (type === "partner") state.partnerReviews.push(data);
 //    if (type === "user") state.userReviews.push(data);
 //    if (type === "video") state.videoTestimonials.push(data);
 //   });

 //   // Deleting
 //   builder.addCase(deleteReview.fulfilled, (state, action) => {
 //    const { type, id } = action.payload;
 //    if (type === "partner")
 //     state.partnerReviews = state.partnerReviews.filter(
 //      (item) => item._id !== id
 //     );
 //    if (type === "user")
 //     state.userReviews = state.userReviews.filter((item) => item._id !== id);
 //    if (type === "video")
 //     state.videoTestimonials = state.videoTestimonials.filter(
 //      (item) => item._id !== id
 //     );
 //   });

 //   // Loading and Errors
 //   builder.addMatcher(
 //    (action) => action.type.endsWith("/pending"),
 //    (state) => {
 //     state.loading = true;
 //     state.error = null;
 //    }
 //   );
 //   builder.addMatcher(
 //    (action) => action.type.endsWith("/rejected"),
 //    (state, action) => {
 //     state.loading = false;
 //     state.error = action.payload;
 //    }
 //   );
 //  },
 extraReducers: (builder) => {
  // Fetching Individual Reviews
  builder.addCase(fetchPartnerReviews.fulfilled, (state, action) => {
   state.partnerReviews = action.payload;
   state.loading = false;
  });
  builder.addCase(fetchUserReviews.fulfilled, (state, action) => {
   state.userReviews = action.payload;
   state.loading = false;
  });
  builder.addCase(fetchVideoTestimonials.fulfilled, (state, action) => {
   state.videoTestimonials = action.payload;
   state.loading = false;
  });

  // Fetching All Reviews
  builder.addCase(fetchAllReviews.pending, (state) => {
   state.loading = true;
   state.error = null;
  });
  builder.addCase(fetchAllReviews.fulfilled, (state, action) => {
   const { partnerReviews, userReviews, videoTestimonials } = action.payload;
   state.partnerReviews = partnerReviews;
   state.userReviews = userReviews;
   state.videoTestimonials = videoTestimonials;
   state.loading = false;
  });
  builder.addCase(fetchAllReviews.rejected, (state, action) => {
   state.loading = false;
   state.error = action.payload;
  });

  // Adding and Deleting Reviews
  builder.addCase(addReview.fulfilled, (state, action) => {
   const { type, data } = action.payload;
   if (type === "partner") state.partnerReviews.push(data);
   if (type === "user") state.userReviews.push(data);
   if (type === "video") state.videoTestimonials.push(data);
  });
  builder.addCase(deleteReview.fulfilled, (state, action) => {
   const { type, id } = action.payload;
   if (type === "partner")
    state.partnerReviews = state.partnerReviews.filter(
     (item) => item._id !== id
    );
   if (type === "user")
    state.userReviews = state.userReviews.filter((item) => item._id !== id);
   if (type === "video")
    state.videoTestimonials = state.videoTestimonials.filter(
     (item) => item._id !== id
    );
  });

  // Loading and Errors
  builder.addMatcher(
   (action) => action.type.endsWith("/pending"),
   (state) => {
    state.loading = true;
    state.error = null;
   }
  );
  builder.addMatcher(
   (action) => action.type.endsWith("/rejected"),
   (state, action) => {
    state.loading = false;
    state.error = action.payload;
   }
  );
 },
});

export default reviewsSlice.reducer;
