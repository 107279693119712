import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk for fetching user data
export const fetchUserData = createAsyncThunk(
 "user/fetchUserData",
 async (token, { rejectWithValue }) => {
  try {
   const response = await axios.get(
    "https://app-api.carexpert.org.in/api/users/user/me",
    {
     headers: { Authorization: `Bearer ${token}` },
    }
   );
   return response.data; // Return user data if the request is successful
  } catch (error) {
   // Reject with error message for the extraReducer
   return rejectWithValue(
    error.response?.data?.message || "Failed to fetch user data"
   );
  }
 }
);

// Redux slice for user state
const userSlice = createSlice({
 name: "user",
 initialState: {
  user: null,
  loading: false,
  error: null,
 },
 reducers: {
  clearUser: (state) => {
   state.user = null; // Clear user data
   state.error = null; // Clear any error message
  },
 },
 extraReducers: (builder) => {
  builder
   // Pending state
   .addCase(fetchUserData.pending, (state) => {
    state.loading = true;
    state.error = null; // Clear any previous error
   })
   // Fulfilled state
   .addCase(fetchUserData.fulfilled, (state, action) => {
    state.loading = false;
    state.user = action.payload; // Update state with user data
   })
   // Rejected state
   .addCase(fetchUserData.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload; // Set error message
   });
 },
});

export const { clearUser } = userSlice.actions; // Export clearUser action
export default userSlice.reducer; // Export user reducer
