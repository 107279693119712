import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch all car models from the API
export const fetchModels = createAsyncThunk("models/fetchModels", async () => {
 try {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/cars/unique"
  );
  return response.data;
 } catch (error) {
  throw new Error(error.response?.data?.message || "Failed to fetch models");
 }
});

// Async thunk to fetch models by a specific brand ID
export const fetchModelsByBrand = createAsyncThunk(
 "models/fetchModelsByBrand",
 async (brandId, { rejectWithValue }) => {
  try {
   const response = await axios.get(
    `https://app-api.carexpert.org.in/api/cars/brand/${brandId}`
   );
   return response.data.data;
  } catch (error) {
   return rejectWithValue(
    error.response?.data?.message || "Failed to fetch models by brand"
   );
  }
 }
);

// Async thunk to find and save a car by details
export const findCarByDetails = createAsyncThunk(
 "models/findCarByDetails",
 async (
  { userId, brand, title, fuelType, transmissionType },
  { rejectWithValue }
 ) => {
  try {
   const findCarResponse = await axios.get(
    "https://app-api.carexpert.org.in/api/cars/find",
    {
     params: { brand, title, fuelType, transmissionType, userId },
    }
   );
   console.log("first", findCarResponse.data);
   localStorage.setItem(
    "MyCarData",
    // JSON.stringify(findCarResponse.data.data.car)
    JSON.stringify(findCarResponse.data.car)
   );
   window.location.reload();
   //  window.location.reload();
   //  const carId = findCarResponse.data?.carId?._id;

   //  const saveCarResponse = await axios.post(
   //   "https://app-api.carexpert.org.in/api/users/select-car",
   //   { userId, carId }
   //  );

   //  localStorage.setItem(
   //   "selectedCar",
   //   JSON.stringify(saveCarResponse.data.data)
   //  );

   return findCarResponse.data.data.car;
  } catch (error) {
   return rejectWithValue(
    error.response?.data?.message || "Failed to find or save car"
   );
  }
 }
);

// Async thunk to switch current car
export const switchCar = createAsyncThunk(
 "models/switchCar",
 async ({ userId, carId }, { rejectWithValue }) => {
  try {
   const response = await axios.post(
    "https://app-api.carexpert.org.in/api/users/switch-car",
    { userId, carId }
   );

   localStorage.setItem("selectedCar", JSON.stringify(response.data));
   return response.data;
  } catch (error) {
   return rejectWithValue(
    error.response?.data?.message || "Failed to switch current car"
   );
  }
 }
);

// Slice to manage car models and car selection
const modelsSlice = createSlice({
 name: "models",
 initialState: {
  models: [],
  selectedCar: JSON.parse(localStorage.getItem("MyCarData")) || null, // Load selected car from localStorage
  currentCar: null,
  loading: false,
  error: null,
 },
 reducers: {},
 extraReducers: (builder) => {
  builder
   // Fetch all models
   .addCase(fetchModels.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchModels.fulfilled, (state, action) => {
    state.loading = false;
    state.models = action.payload;
   })
   .addCase(fetchModels.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   })
   // Fetch models by brand
   .addCase(fetchModelsByBrand.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchModelsByBrand.fulfilled, (state, action) => {
    state.loading = false;
    state.models = action.payload;
   })
   .addCase(fetchModelsByBrand.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   })
   // Find and save car by details
   .addCase(findCarByDetails.pending, (state) => {
    state.loading = true;
   })
   .addCase(findCarByDetails.fulfilled, (state, action) => {
    state.loading = false;
    state.selectedCar = action.payload;
    localStorage.setItem("MyCarData", JSON.stringify(action.payload));
   })
   .addCase(findCarByDetails.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   })
   // Switch car
   .addCase(switchCar.pending, (state) => {
    state.loading = true;
   })
   .addCase(switchCar.fulfilled, (state, action) => {
    state.loading = false;
    state.currentCar = action.payload.currentCar;
   })
   .addCase(switchCar.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload;
   });
 },
});

export default modelsSlice.reducer;
