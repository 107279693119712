import {
 Box,
 Image,
 Text,
 Menu,
 MenuButton,
 MenuList,
 MenuItem,
 Stack,
 useDisclosure,
 useBreakpointValue,
 Avatar,
} from "@chakra-ui/react";
import { ChevronDownIcon } from "@chakra-ui/icons";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const AccountAndLists = () => {
 const location = useLocation();
 const { isOpen, onOpen, onClose } = useDisclosure();
 const { user, isAuth } = useSelector((state) => state.auth);
 const [isVisible, setIsVisible] = useState(true); // Controls navbar visibility
 const [lastScrollY, setLastScrollY] = useState(0); // Tracks last scroll position
 const [isModalOpen, setModalOpen] = useState(false);
 const [selectedCity, setSelectedCity] = useState("Select City");
 const isMobile = useBreakpointValue({ base: true, md: false });

 const handleCitySelect = (city) => {
  setSelectedCity(city); // Update selected city
  setModalOpen(false); // Close modal
 };

 useEffect(() => {
  const handleScroll = () => {
   const currentScrollY = window.scrollY;

   if (currentScrollY > lastScrollY) {
    setIsVisible(false); // Hide navbar on scroll down
   } else {
    setIsVisible(true); // Show navbar on scroll up
   }

   setLastScrollY(currentScrollY);
  };

  window.addEventListener("scroll", handleScroll);

  return () => {
   window.removeEventListener("scroll", handleScroll);
  };
 }, [lastScrollY]);

 //  const location = useLocation();
 const handleLogout = () => {
  console.log("User logged out");
  // Add your logout logic here
 };
 return (
  <Box
   display={{ base: "flex", md: "none", lg: "none" }}
   alignItems="center"
   gap={1}
  >
   <Menu>
    <MenuButton
     display="flex"
     alignItems="center"
     gap={1}
     as={Box}
     cursor="pointer"
    >
     <Avatar
      name={"Chandan"}
      src={"Chandan"}
      size="sm"
      borderRadius="100%"
      mb={3}
     />
     {/* <Text fontSize="sm">bengaluru</Text>
      <ChevronDownIcon /> */}
    </MenuButton>
    <MenuList>
     <MenuItem>English</MenuItem>
     <MenuItem>Hindi</MenuItem>
    </MenuList>
   </Menu>
   {/* Greeting and Account Links */}
   <Stack spacing={0} lineHeight="0.2">
    <Text fontSize="xs">Hello, Priyadarshi</Text>
    <Text fontSize="sm" fontWeight="bold">
     Account & Lists
    </Text>
   </Stack>
  </Box>
 );
};

export default AccountAndLists;
//    <Box display="flex" alignItems="center" gap={2} color="white">
//    {/* Language Selector */}
//    <Menu>
//     <MenuButton
//      display="flex"
//      alignItems="center"
//      gap={1}
//      as={Box}
//      cursor="pointer"
//     >
//      <Image
//       src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
//       alt="India Flag"
//       boxSize="20px"
//      />
//      <Text fontSize="sm">EN</Text>
//      <ChevronDownIcon />
//     </MenuButton>
//     <MenuList>
//      <MenuItem>English</MenuItem>
//      <MenuItem>Hindi</MenuItem>
//     </MenuList>
//    </Menu>

//    {/* Account Info */}
//    <Stack spacing={0} lineHeight="1.2">
//     <Text fontSize="xs">Hello, Priyadarshi</Text>
//     <Text fontSize="sm" fontWeight="bold">
//      Account & Lists
//     </Text>
//    </Stack>
//   </Box>
//   <Box
//    display={{ base: "flex", md: "none", lg: "none" }}
//    alignItems={"center"}
//    justifyContent={"flex-start"}
//    gap={4}
//   >
//    {/* Serving City Dropdown */}
//    <Box
//     onClick={() => setModalOpen(true)}
//     bg="transparent"
//     color="white"
//     borderRadius="full"
//     fontWeight="bold"
//     fontSize="sm"
//     display={{ base: "inline-flex", md: "none", lg: "none" }}
//     alignItems="center"
//     px={2}
//     cursor="pointer"
//    >
//     {selectedCity === "Select City" ? "Serving City" : selectedCity}
//     <ChevronDownIcon boxSize={5} ml={1} />
//    </Box>

//    {/* Account & Lists Section */}
//    <Box display="flex" alignItems="center" gap={2}>
//     {/* Language Selector */}
//     <Menu>
//      <MenuButton
//       display="flex"
//       alignItems="center"
//       gap={1}
//       as={Box}
//       cursor="pointer"
//      >
//       <Image
//        src="https://upload.wikimedia.org/wikipedia/en/thumb/4/41/Flag_of_India.svg/1200px-Flag_of_India.svg.png"
//        alt="India Flag"
//        boxSize="20px"
//       />
//       <Text fontSize="sm">EN</Text>
//       <ChevronDownIcon />
//      </MenuButton>
//      <MenuList>
//       <MenuItem>English</MenuItem>
//       <MenuItem>Hindi</MenuItem>
//      </MenuList>
//     </Menu>

//     {/* Greeting and Account Links */}
//     <Stack spacing={0} lineHeight="1.2">
//      <Text fontSize="xs">Hello, Priyadarshi</Text>
//      <Text fontSize="sm" fontWeight="bold">
//       Account & Lists
//      </Text>
//     </Stack>
//    </Box>

//    {/* Avatar Section */}
//    {isAuth && (
//     <Box
//      display={{ base: "flex", md: "none" }}
//      flexDirection="column"
//      alignItems="center"
//     >
//      <Avatar
//       name={"Chandan"}
//       src={"Chandan"}
//       size="sm"
//       borderRadius="100%"
//       mt={2}
//      />
//      <Text mt={0} mr={2} fontSize="sm" textAlign="center">
//       {"Pushpendra Singh"}
//      </Text>
//     </Box>
//    )}
//   </Box>
<Box
//    border={"1px solid red"}
//    height={"50px"}
//    display={"flex"}
//    alignItems={"center"}
//    justifyContent={"center"}
//    gap={2}
>
 {/* Serving City Dropdown */}
 {/* <Box
    onClick={() => setModalOpen(true)}
    bg="transparent"
    color="white"
    borderRadius="full"
    fontWeight="bold"
    fontSize="sm"
    display={{ base: "inline-flex", md: "none", lg: "none" }}
    alignItems="center"
    px={2}
    cursor="pointer"
   >
    {selectedCity === "Select City" ? "Serving City" : selectedCity}
    <ChevronDownIcon boxSize={5} ml={1} />
   </Box> */}

 {/* Account & Lists Section */}

 {/* Avatar Section
   {isAuth && (
    <Box
     display={{ base: "flex", md: "none" }}
     flexDirection="column"
     alignItems="center"
    >
     <Avatar
      name={"Chandan"}
      src={"Chandan"}
      size="sm"
      borderRadius="100%"
      mt={2}
     />
     <Text mt={0} mr={2} fontSize="sm" textAlign="center">
      {"Pushpendra Singh"}
     </Text>
    </Box>
   )} */}
</Box>;
