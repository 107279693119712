import React from "react";
import { IconButton, Tooltip } from "@chakra-ui/react";
import { FiLogOut } from "react-icons/fi";
import { useDispatch } from "react-redux"; // Import useDispatch for Redux
import { logout } from "../../redux/authSlice";

const LogoutButton = () => {
 const dispatch = useDispatch();

 const handleLogout = () => {
  // Dispatch the logout action
  dispatch(logout());
  // Additional logout logic, e.g., redirecting or clearing local storage
  console.log("User logged out");
 };

 return (
  <Tooltip label="Logout" fontSize="md" bg="blue.600" color="white">
   <IconButton
    icon={<FiLogOut />}
    aria-label="Logout"
    size="lg"
    fontSize="24px"
    colorScheme="blue"
    borderRadius="full"
    boxShadow="md"
    transition="all 0.3s ease-in-out"
    _hover={{
     bg: "blue.700",
     transform: "scale(1.1)",
    }}
    _active={{
     bg: "blue.800",
     transform: "scale(0.95)",
    }}
    onClick={handleLogout} // Trigger the logout function
   />
  </Tooltip>
 );
};

export default LogoutButton;
