import React, { useState } from "react";
import {
 Box,
 Flex,
 Text,
 Button,
 Drawer,
 DrawerOverlay,
 DrawerContent,
 DrawerHeader,
 DrawerBody,
 DrawerCloseButton,
 useBreakpointValue,
} from "@chakra-ui/react";
import CartCard from "../UI/CartCardPage";
import { useSelector } from "react-redux";

const MobileCartBar = ({ handleQuantityChange, handleRemove }) => {
 // Manage drawer open/close state
 const [isDrawerOpen, setDrawerOpen] = useState(false);
 const { cart, couponCodes, status, error } = useSelector(
  (state) => state.cart
 );
 // Determine if we’re on mobile
 const isMobile = useBreakpointValue({ base: true, md: false });

 // Dummy data for demonstration
 const serviceCount = 1;
 const totalPrice = 2199;

 // Handlers
 const openDrawer = () => setDrawerOpen(true);
 const closeDrawer = () => setDrawerOpen(false);

 // Show bottom bar only on mobile
 if (!isMobile) return null;
 console.log("cart:", cart);
 return (
  <>
   {/* Bottom fixed bar */}
   <Box
    position="fixed"
    bottom={0}
    left={0}
    right={0}
    bg="blue.700"
    color="white"
    p={4}
    zIndex={999} // Ensure it stays above other elements
   >
    <Flex align="center" justify="space-between" flexWrap="wrap">
     {/* Left side info: service count + total price */}
     <Box>
      <Text fontSize="sm">{cart?.totalItems} Service Added</Text>
      <Text fontSize="xl" fontWeight="bold">
       ₹ {cart?.totalPrice.toLocaleString()}
      </Text>
     </Box>

     {/* Right side button */}
     <Button
      colorScheme="whiteAlpha"
      variant="outline"
      onClick={openDrawer}
      rightIcon={
       <Text as="span" fontSize="lg" ml={1}>
        ➤
       </Text>
      }
     >
      VIEW CART
     </Button>
    </Flex>
   </Box>

   {/* Drawer for Cart - Open from Bottom */}
   <Drawer
    isOpen={isDrawerOpen}
    placement="bottom" // Open from the bottom
    onClose={closeDrawer}
   >
    <DrawerOverlay />
    <DrawerContent h="75%">
     <DrawerCloseButton />
     <DrawerHeader>Your Cart</DrawerHeader>
     <DrawerBody>
      {/* Render the CartCard inside the drawer */}
      <CartCard
       cartItems={cart?.items}
       coupon={couponCodes}
       handleQuantityChange={handleQuantityChange}
       handleRemove={handleRemove}
      />
     </DrawerBody>
    </DrawerContent>
   </Drawer>
  </>
 );
};

export default MobileCartBar;
