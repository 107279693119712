// src/redux/faqSlice.js
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async Thunk to Fetch FAQs
export const fetchFaqs = createAsyncThunk(
 "faqs/fetchFaqs",
 async (_, { rejectWithValue }) => {
  try {
   const response = await axios.get(
    "https://app-api.carexpert.org.in/api/faqs"
   );
   return response.data; // Return the API response data
  } catch (error) {
   return rejectWithValue(error.response?.data || error.message); // Return the error message
  }
 }
);

// Slice Definition
const faqSlice = createSlice({
 name: "faqs",
 initialState: {
  data: [],
  loading: false,
  error: null,
 },
 reducers: {},
 extraReducers: (builder) => {
  builder
   .addCase(fetchFaqs.pending, (state) => {
    state.loading = true;
    state.error = null;
   })
   .addCase(fetchFaqs.fulfilled, (state, action) => {
    state.loading = false;
    state.data = action.payload; // Store the FAQ data
   })
   .addCase(fetchFaqs.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload; // Store the error message
   });
 },
});

export default faqSlice.reducer;
