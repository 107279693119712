import React, { useState, useEffect } from "react";
import {
 Box,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 Grid,
 GridItem,
 Image,
 Text,
 Input,
 Drawer,
 DrawerOverlay,
 DrawerContent,
 DrawerHeader,
 DrawerBody,
 useBreakpointValue,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import axios from "axios";

const MotionModalContent = motion(ModalContent);
const MotionDrawerContent = motion(DrawerContent);

const CitySelectionModal = ({ isOpen, onClose }) => {
 const isMobile = useBreakpointValue({ base: true, md: false }); // Check screen size
 const [cities, setCities] = useState([]);
 const [filteredCities, setFilteredCities] = useState([]);
 const [searchQuery, setSearchQuery] = useState("");

 // Fetch cities from API
 useEffect(() => {
  const fetchCities = async () => {
   try {
    const response = await axios.get(
     "https://app-api.carexpert.org.in/api/cities"
    );
    setCities(response.data);
    setFilteredCities(response.data);
   } catch (error) {
    console.error("Error fetching cities:", error);
   }
  };

  fetchCities();
 }, []);

 // Handle search input
 const handleSearch = (e) => {
  const query = e.target.value.toLowerCase();
  setSearchQuery(query);

  // Filter cities based on the search query
  const filtered = cities.filter((city) =>
   city.city.toLowerCase().includes(query)
  );
  setFilteredCities(filtered);
 };

 // Animation variants
 const animationVariants = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
  exit: { opacity: 0, y: 50 },
 };

 const CommonContent = () => (
  <>
   {/* Search Box */}
   <Box mb={4}>
    <Input
     placeholder="Search City..."
     value={searchQuery}
     onChange={handleSearch}
     border="1px solid gray.300"
     borderRadius="md"
     focusBorderColor="blue.500"
     _placeholder={{ color: "gray.400" }}
    />
   </Box>

   {/* City Grid */}
   <Grid templateColumns="repeat(auto-fit, minmax(60px, 1fr))" gap={6}>
    {filteredCities.length > 0 ? (
     filteredCities.map((city) => (
      <GridItem
       key={city._id}
       textAlign="center"
       cursor="pointer"
       onClick={() => {
        onClose(city.city); // Pass selected city back to parent
       }}
       _hover={{
        transform: "scale(1.1)",
        backgroundColor: "blue.50",
       }}
       transition="transform 0.3s, background-color 0.3s"
       p={3}
       borderRadius="md"
       boxShadow="sm"
      >
       <Image
        src={city.image}
        alt={city.city}
        boxSize="40px"
        borderRadius="full"
        mx="auto"
        mb={2}
        border="1px solid blue.100"
       />
       <Text fontSize="10px" fontWeight="bold" color="blue.700">
        {city.city}
       </Text>
      </GridItem>
     ))
    ) : (
     <Text textAlign="center" w="full" fontSize="lg" color="gray.500">
      No cities found
     </Text>
    )}
   </Grid>
  </>
 );

 return isMobile ? (
  <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
   <DrawerOverlay />
   <MotionDrawerContent
    initial="hidden"
    animate="visible"
    exit="exit"
    variants={animationVariants}
    transition={{ duration: 0.5 }}
    drag="y"
    dragConstraints={{ top: 0, bottom: 100 }}
    onDragEnd={(event, info) => {
     if (info.offset.y > 100) {
      onClose(); // Close the drawer on swipe down
     }
    }}
    bg="white"
    borderTopRadius="lg"
    p={4}
    boxShadow="lg"
   >
    <DrawerHeader bg="#000D6B" color="white" borderTopRadius="lg" py={3}>
     Choose Your City
    </DrawerHeader>
    <DrawerBody>{CommonContent()}</DrawerBody>
   </MotionDrawerContent>
  </Drawer>
 ) : (
  <Modal isOpen={isOpen} onClose={onClose} size="xl" isCentered>
   <ModalOverlay />
   <MotionModalContent
    initial="hidden"
    animate="visible"
    exit="exit"
    variants={animationVariants}
    transition={{ duration: 0.5 }}
    borderRadius="lg"
    boxShadow="lg"
   >
    <ModalHeader bg="#000D6B" color="white" borderTopRadius="lg" py={3}>
     Choose Your City
    </ModalHeader>
    <ModalCloseButton color="white" />
    <ModalBody>{CommonContent()}</ModalBody>
   </MotionModalContent>
  </Modal>
 );
};

export default CitySelectionModal;

// import React, { useState, useEffect } from "react";
// import {
//  Box,
//  Modal,
//  ModalOverlay,
//  ModalContent,
//  ModalHeader,
//  ModalCloseButton,
//  ModalBody,
//  Grid,
//  GridItem,
//  Image,
//  Text,
//  Input,
//  useDisclosure,
// } from "@chakra-ui/react";
// import axios from "axios";

// const CitySelectionModal = ({ isOpen, onClose }) => {
//  const [cities, setCities] = useState([]);
//  const [filteredCities, setFilteredCities] = useState([]);
//  const [searchQuery, setSearchQuery] = useState("");

//  // Fetch cities from API
//  useEffect(() => {
//   const fetchCities = async () => {
//    try {
//     const response = await axios.get(
//      "https://app-api.carexpert.org.in/api/cities"
//     );
//     setCities(response.data);
//     setFilteredCities(response.data);
//    } catch (error) {
//     console.error("Error fetching cities:", error);
//    }
//   };

//   fetchCities();
//  }, []);

//  // Handle search input
//  const handleSearch = (e) => {
//   const query = e.target.value.toLowerCase();
//   setSearchQuery(query);

//   // Filter cities based on the search query
//   const filtered = cities.filter((city) =>
//    city.city.toLowerCase().includes(query)
//   );
//   setFilteredCities(filtered);
//  };

//  return (
//   <Modal isOpen={isOpen} onClose={onClose} size="xl">
//    <ModalOverlay />
//    <ModalContent borderRadius="lg" boxShadow="lg">
//     <ModalHeader
//      mt={-14}
//      bg="blue.500"
//      color="white"
//      borderTopRadius="lg"
//      py={3}
//     >
//      Choose Your City
//     </ModalHeader>
//     <ModalCloseButton color="white" />
//     <ModalBody>
//      {/* Search Box */}
//      <Box mb={4}>
//       <Input
//        placeholder="Search City..."
//        value={searchQuery}
//        onChange={handleSearch}
//        border="1px solid gray.300"
//        borderRadius="md"
//        focusBorderColor="blue.500"
//        _placeholder={{ color: "gray.400" }}
//       />
//      </Box>

//      {/* City Grid */}
//      <Grid templateColumns="repeat(4, 1fr)" gap={6}>
//       {filteredCities.length > 0 ? (
//        filteredCities.map((city) => (
//         <GridItem
//          key={city._id}
//          textAlign="center"
//          cursor="pointer"
//          onClick={() => {
//           onClose(city.city); // Pass selected city back to parent
//          }}
//          _hover={{
//           transform: "scale(1.1)",
//           backgroundColor: "blue.50",
//          }}
//          transition="transform 0.3s, background-color 0.3s"
//          p={3}
//          borderRadius="md"
//          boxShadow="sm"
//         >
//          <Image
//           src={city.image}
//           alt={city.city}
//           boxSize="70px"
//           borderRadius="full"
//           mx="auto"
//           mb={2}
//           border="1px solid blue.100"
//          />
//          <Text fontSize="md" fontWeight="bold" color="blue.700">
//           {city.city}
//          </Text>
//         </GridItem>
//        ))
//       ) : (
//        <Text textAlign="center" w="full" fontSize="lg" color="gray.500">
//         No cities found
//        </Text>
//       )}
//      </Grid>
//     </ModalBody>
//    </ModalContent>
//   </Modal>
//  );
// };

// export default CitySelectionModal;
