import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Async Thunk for Fetching Data
export const fetchCompanyData = createAsyncThunk(
 "company/fetchCompanyData",
 async (_, { rejectWithValue }) => {
  try {
   const response = await axios.get(
    "https://app-api.carexpert.org.in//api/company"
   );
   return response.data; // Return data if the API call succeeds
  } catch (error) {
   return rejectWithValue(error.response ? error.response.data : error.message); // Handle errors
  }
 }
);

// Slice Definition
const companySlice = createSlice({
 name: "company",
 initialState: {
  data: null,
  loading: false,
  error: null,
 },
 reducers: {},
 extraReducers: (builder) => {
  builder
   .addCase(fetchCompanyData.pending, (state) => {
    state.loading = true; // API call started
    state.error = null; // Clear previous errors
   })
   .addCase(fetchCompanyData.fulfilled, (state, action) => {
    state.loading = false; // API call succeeded
    state.data = action.payload; // Store fetched data
   })
   .addCase(fetchCompanyData.rejected, (state, action) => {
    state.loading = false; // API call failed
    state.error = action.payload; // Store error message
   });
 },
});

export default companySlice.reducer;
