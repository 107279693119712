import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import axios from "axios";

// Async thunk to fetch cars by IDs
export const fetchCarsByIds = createAsyncThunk(
 "cars/fetchCarsByIds",
 async ({ ids }, { rejectWithValue }) => {
  try {
   // Make a POST request to the server with the array of IDs
   const response = await axios.post(
    "https://app-api.carexpert.org.in/api/cars",
    {
     ids,
    }
   );

   // Assuming the server responds with an array of car details in `response.data.data`
   return response.data.data;
  } catch (error) {
   // Handle error and return a custom error message
   return rejectWithValue(
    error.response?.data?.message || "Failed to fetch car data"
   );
  }
 }
);

// Slice for managing cars data
const carsSlice = createSlice({
 name: "cars",
 initialState: {
  cars: [], // To store the fetched car data
  loading: false,
  error: null,
 },
 reducers: {},
 extraReducers: (builder) => {
  builder
   .addCase(fetchCarsByIds.pending, (state) => {
    state.loading = true;
    state.error = null;
   })
   .addCase(fetchCarsByIds.fulfilled, (state, action) => {
    state.loading = false;
    state.cars = action.payload;
   })
   .addCase(fetchCarsByIds.rejected, (state, action) => {
    state.loading = false;
    state.error = action.payload || "Failed to fetch car data";
   });
 },
});

export default carsSlice.reducer;
