import React, { useEffect, useState } from "react";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import {
 fetchCategoriesAndSubCategories,
 fetchProducts,
 selectCategory,
 selectSubCategory,
} from "../../redux/servicesSlice";
import CartCard from "../UI/CartCardPage";
import {
 Box,
 Heading,
 HStack,
 Image,
 Button,
 Spinner,
 VStack,
 Text,
 SimpleGrid,
 Flex,
 Badge,
 List,
 ListItem,
 Icon,
 Grid,
 useBreakpointValue,
} from "@chakra-ui/react";
import {
 addToCart,
 fetchCart,
 removeProductFromCart,
} from "../../redux/cartSlice";
import { Link } from "react-router-dom";
import OriginalSpareParts from "../UI/OriginalSpareParts";
import { CheckCircleIcon } from "@chakra-ui/icons";
import Slider from "react-slick";
import MobileCartBar from "./MobileCartBar";

const ProductListingPage = () => {
 const [expandedCard, setExpandedCard] = useState(null); // State to track expanded card

 const toggleShowMore = (productId) => {
  setExpandedCard((prev) => (prev === productId ? null : productId));
 };

 const dispatch = useDispatch();
 const { isAuth, user } = useSelector((state) => state.auth);
 const {
  categories,
  subCategories,
  selectedCategory,
  selectedSubCategory,
  products,
  loading,
 } = useSelector((state) => state.services);
 const { cart, couponCodes, status, error } = useSelector(
  (state) => state.cart
 );
 const isMobile = useBreakpointValue({ base: true, md: false });

 const { currentCar: { _id: currentCarId } = {}, _id: userId = null } =
  user || {};

 // Fetch categories, subcategories, and products on mount
 useEffect(() => {
  dispatch(fetchCategoriesAndSubCategories());
 }, [dispatch]);

 // Fetch cart data on user change
 useEffect(() => {
  if (userId) {
   dispatch(fetchCart(userId));
  }
 }, [userId, dispatch]);
 useEffect(() => {
  if (categories.length > 0) {
   const firstCategory = categories[0];
   if (firstCategory) {
    dispatch(selectCategory(firstCategory._id));

    const firstSubCategory = subCategories.find(
     (sub) => sub.categoryId === firstCategory._id
    );

    if (firstSubCategory) {
     dispatch(selectSubCategory(firstSubCategory._id));

     dispatch(
      fetchProducts({
       subCategoryId: firstSubCategory._id,
       carId: currentCarId,
      })
     );
    }
   }
  }
 }, [categories, subCategories, dispatch, currentCarId]);

 // Handle category change
 const handleCategoryChange = (categoryId) => {
  dispatch(selectCategory(categoryId));

  const firstSubCategory = subCategories.find(
   (sub) => sub.categoryId === categoryId
  );
  if (firstSubCategory) {
   dispatch(selectSubCategory(firstSubCategory._id));

   dispatch(
    fetchProducts({ subCategoryId: firstSubCategory._id, carId: currentCarId })
   );
  }
 };
 //  // Handle category change
 //  const handleCategoryChange = (categoryId) => {
 //   dispatch(selectCategory(categoryId));

 //   const firstSubCategory = subCategories.find(
 //    (sub) => sub.categoryId === categoryId
 //   );
 //   if (firstSubCategory) {
 //    console.log("firstSubCategory:", firstSubCategory);
 //    dispatch(selectSubCategory(firstSubCategory._id));

 //    dispatch(
 //     fetchProducts({ subCategoryId: firstSubCategory._id, carId: currentCarId })
 //    );
 //   }
 //  };

 // Handle subcategory change
 const handleSubCategoryChange = (subCategoryId) => {
  dispatch(selectSubCategory(subCategoryId));

  dispatch(fetchProducts({ subCategoryId, carId: currentCarId }));
 };

 // Handle add to cart
 const handleAddToCart = (product, ProductType) => {
  const {
   _id: productId,
   subCategoryId: { _id: subCategoryId },
  } = product;

  if (productId && subCategoryId && currentCarId && userId && ProductType) {
   dispatch(
    addToCart({ productId, subCategoryId, currentCarId, userId, ProductType })
   );
   dispatch(fetchCart(userId));
  } else {
   console.error("Missing required fields:", {
    productId,
    subCategoryId,
    currentCarId,
    userId,
   });
  }
 };

 // Handle removing items from cart
 const handleRemove = (item) => {
  const productId = item?.productId;
  const subCategoryId = item?.subCategoryId;

  if (productId && subCategoryId) {
   dispatch(
    removeProductFromCart({
     userId: user._id,
     productId,
     subCategoryId,
    })
   );
  } else {
   console.error("Missing productId or subCategoryId!");
  }
 };

 // Check if product is in cart
 const isProductInCart = (productId) => {
  return cart?.items?.some((item) => item.productId === productId);
 };

 const categoryImages = [
  "/car-repair-svgrepo-com.svg",
  "/auto-paint-service-svgrepo-com.svg",
  "/car-washing-svgrepo-com.svg",
  "/auto-mechanic-car-mechanic-car-repair-svgrepo-com.svg",
 ];

 const handleQuantityChange = (id, delta) => {
  // ...
 };
 const settings = {
  dots: false, // Show dots for navigation
  infinite: true, // Infinite loop scrolling
  speed: 500, // Transition speed
  slidesToShow: 5, // Number of slides visible at once
  slidesToScroll: 1, // Number of slides to scroll at a time
  responsive: [
   {
    breakpoint: 1024, // Adjust for large screens
    settings: {
     slidesToShow: 5,
    },
   },
   {
    breakpoint: 768, // Adjust for medium screens
    settings: {
     slidesToShow: 3,
    },
   },
   {
    breakpoint: 480, // Adjust for small screens
    settings: {
     slidesToShow: 2,
    },
   },
  ],
 };
 return (
  <Flex
   /* Responsive container */
   direction={{ base: "column", md: "row" }}
   w="90%"
   justify="center"
   align="flex-start"
   gap={6}
   m={{ base: "20px auto", md: "30px auto", lg: "40px auto" }}
  >
   {/* Left Section - Categories, Subcategories, and Products */}
   <Box w={{ base: "100%", md: "70%" }}>
    {loading && <Spinner size="lg" />}
    {/* Category List */}
    <Slider {...settings}>
     {categories.map((category) => (
      <Box
       key={category?._id}
       borderRadius="md"
       bg={category._id === selectedCategory ? "blue.100" : "white"}
       p={4}
       boxShadow="md"
       textAlign="center"
       cursor="pointer"
       _hover={{ transform: "scale(1.05)", transition: "0.3s" }}
       onClick={() => handleCategoryChange(category?._id)}
      >
       <Image
        src={category?.categoryImage}
        alt={category.categoryName}
        boxSize="40px"
        mx="auto"
        mb={2}
       />
       <Heading fontSize="10px" noOfLines={2}>
        {category.categoryName}
       </Heading>
      </Box>
     ))}
    </Slider>
    {/* SubCategory List */}
    <HStack spacing={3} mb={4} overflowX="auto">
     {subCategories
      .filter((sub) => sub.categoryId === selectedCategory)
      .map((sub) => (
       <Button
        key={sub?._id}
        colorScheme={sub?._id === selectedSubCategory ? "green" : "gray"}
        onClick={() => handleSubCategoryChange(sub?._id)}
        flexShrink={0}
       >
        {sub.subCategoryName}
       </Button>
      ))}
    </HStack>
    {/* Product List */}
    <VStack align="stretch" spacing={4}>
     <Heading size="md">Products</Heading>
     <Box w="100%" mt={6}>
      {loading ? (
       <VStack justify="center" align="center" h="200px">
        <Spinner size="xl" color="blue.500" />
        <Text>Loading products...</Text>
       </VStack>
      ) : products[selectedSubCategory] &&
        products[selectedSubCategory].length > 0 ? (
       <SimpleGrid columns={1} spacing={2}>
        {products[selectedSubCategory].map((product) => (
         <Flex
          box={"1px solid #000D6B"}
          minH="160px"
          key={product?._id}
          direction={{ base: "column", md: "row" }} // Responsive layout
          borderRadius="md"
          borderWidth="1px"
          // boxShadow="md"
          sx={{
           boxShadow: "0 4px 6px #cccc)", // Blue shadow
          }}
          p={4}
          bg="#f9f9f9"
          align="stretch"
         >
          {/* Product Image */}
          <Image
           src={
            product.productImage ||
            "https://m.media-amazon.com/images/I/51-6Y+fNmAL._SX522_.jpg"
           }
           alt={product.productName}
           boxSize={{ base: "100px", md: "80px" }} // Full width for mobile
           objectFit="cover"
           borderRadius="md"
           bg="white"
           mb={{ base: 4, md: 0 }} // Add margin on mobile
          />

          {/* Product Details */}
          <Box flex="1" px={4}>
           <Heading size="sm" fontWeight="bold" mb={2}>
            {product.productName || "Basic Car Service"}
           </Heading>

           <SimpleGrid columns={{ base: 2, md: 2, lg: 3 }} spacing={2} w="100%">
            {/* Highlights */}
            {product.highlights?.map((highlight, index) => (
             <Text
              key={index}
              lineHeight={"5px"}
              fontSize="sm"
              color="gray.700"
             >
              <Icon as={CheckCircleIcon} color="red.500" mr={2} />
              {highlight.text || "Highlight"}
             </Text>
            ))}

            {/* Show additional details if this card is expanded */}
            {expandedCard === product._id && (
             <>
              {product.additionalServices?.map((highlight, index) => (
               <Text key={index} fontSize="sm" color="gray.700">
                <Icon as={CheckCircleIcon} color="red.500" mr={2} />
                {highlight || "Additional Service"}
               </Text>
              ))}
              {product.includedService?.map((serviceGroup) =>
               serviceGroup.services.map((service) => (
                <Text key={service._id} fontSize="sm" color="gray.700">
                 <Icon as={CheckCircleIcon} color="red.500" mr={2} />
                 {service.title}
                </Text>
               ))
              )}
             </>
            )}
           </SimpleGrid>

           {/* Show More / Show Less Button */}
           <Button
            size="sm"
            mt={2}
            onClick={() => toggleShowMore(product._id)}
            colorScheme="blue"
            variant="link"
           >
            {expandedCard === product._id ? "Show Less" : "Show More"}
           </Button>
          </Box>

          {/* Price and Add to Cart Section */}
          <VStack
           align="stretch"
           spacing={2}
           mt={{ base: 4, md: 0 }}
           textAlign={{ base: "left", md: "right" }} // Align text based on screen size
          >
           <Text fontSize="lg" fontWeight="bold" color="red.600">
            ₹{product.price || "2497"}
           </Text>
           <Button
            colorScheme="red"
            size="sm"
            px={6}
            _hover={{ bg: "red.600" }}
            onClick={() => handleAddToCart(product, "Product")}
            isDisabled={isProductInCart(product?._id)}
           >
            {isProductInCart(product?._id)
             ? "Already in Cart"
             : "Add To Cart +"}
           </Button>
          </VStack>
         </Flex>
        ))}
       </SimpleGrid>
      ) : (
       <VStack justify="center" align="center" h="200px">
        <Text fontSize="lg" color="gray.500">
         No products found
        </Text>
       </VStack>
      )}
     </Box>
    </VStack>
    <OriginalSpareParts />
   </Box>

   {/* Right Section - Cart */}
   <Box w={{ base: "100%", md: "30%" }} mt={{ base: 6, md: 0 }}>
    <MobileCartBar
     cartItems={cart?.items}
     coupon={couponCodes}
     handleQuantityChange={handleQuantityChange}
     handleRemove={handleRemove}
    />
    {!isMobile && (
     <CartCard
      cartItems={cart?.items}
      coupon={couponCodes}
      handleQuantityChange={handleQuantityChange}
      handleRemove={handleRemove}
     />
    )}
   </Box>
  </Flex>
 );
};

export default ProductListingPage;
