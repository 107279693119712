// import React from "react";
// import {
//  Box,
//  Heading,
//  Text,
//  Image,
//  Flex,
//  SimpleGrid,
//  List,
//  ListItem,
//  Icon,
//  Modal,
//  ModalOverlay,
//  ModalContent,
//  ModalHeader,
//  ModalCloseButton,
//  ModalBody,
//  ModalFooter,
//  Button,
//  Drawer,
//  DrawerOverlay,
//  DrawerContent,
//  DrawerHeader,
//  DrawerBody,
//  DrawerFooter,
//  useBreakpointValue,
// } from "@chakra-ui/react";
// import { CheckCircleIcon } from "@chakra-ui/icons";
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

// const ProductDetailsPage = ({ product, isOpen, onClose }) => {
//  const isMobile = useBreakpointValue({ base: true, md: false });

//  const {
//   productName = "Sample Product",
//   productBannerImages = [],
//   highlights = [],
//   includedService = [],
//   additionalServices = [],
//   ratings = 4.5,
//   mrp = 1000,
//   price = 800,
//   offerTag = [],
//  } = product;

//  const settings = {
//   fade: true,
//   speed: 2000,
//   autoplaySpeed: 3000,
//   infinite: true,
//   autoplay: true,
//   slidesToShow: 1,
//   slidesToScroll: 1,
//   arrows: false,
//   pauseOnHover: false,
//  };
//  console.log("product:", product);
//  const content = (
//   <Box w="100%" fontFamily="Arial, sans-serif" lineHeight="1.6" p={[3, 5, 8]}>
//    {/* Header */}
//    <Box textAlign="center" mb="8">
//     <Slider {...settings}>
//      {product?.productBannerImages?.map((imageUrl, index) =>
//       console.log("imageUrl:", imageUrl)
//      )}
//     </Slider>
//     <Heading as="h1" mt="4" fontSize={["lg", "xl", "2xl"]}>
//      {productName}
//     </Heading>
//     <Text fontSize={["sm", "md", "lg"]} color="gray.500" mt="2">
//      ⭐ {ratings} | MRP:{" "}
//      <Text as="s" display="inline">
//       ₹{mrp}
//      </Text>{" "}
//      | Price: ₹{price}
//     </Text>
//    </Box>

//    {/* Highlights */}
//    <Box mb="8">
//     <Heading as="h2" size="md" mb="4" fontSize={["md", "lg", "xl"]}>
//      Highlights
//     </Heading>
//     <SimpleGrid columns={[1, 2, 3]} spacing="5">
//      {highlights.map((highlight, index) => (
//       <Flex
//        key={index}
//        align="center"
//        gap="2"
//        bg="gray.100"
//        p="4"
//        borderRadius="5"
//       >
//        <Image
//         src={
//          highlight.icon ||
//          "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg"
//         }
//         alt={highlight.text}
//         boxSize="24px"
//        />
//        <Text fontSize={["sm", "md"]}>{highlight.text}</Text>
//       </Flex>
//      ))}
//     </SimpleGrid>
//    </Box>
//   </Box>
//  );

//  console.log("content", content);
//  return isMobile ? (
//   // Drawer for mobile
//   <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
//    <DrawerOverlay />
//    <DrawerContent>
//     <DrawerHeader>{productName}</DrawerHeader>
//     <DrawerBody>{content}</DrawerBody>
//     <DrawerFooter>
//      <Button variant="outline" onClick={onClose}>
//       Close
//      </Button>
//     </DrawerFooter>
//    </DrawerContent>
//   </Drawer>
//  ) : (
//   // Modal for desktop
//   <Modal isOpen={isOpen} onClose={onClose} size="xl">
//    <ModalOverlay />
//    <ModalContent>
//     <ModalHeader>{productName}</ModalHeader>
//     <ModalCloseButton />
//     <ModalBody>{content}</ModalBody>
//     <ModalFooter>
//      <Button variant="outline" onClick={onClose}>
//       Close
//      </Button>
//     </ModalFooter>
//    </ModalContent>
//   </Modal>
//  );
// };

// export default ProductDetailsPage;

import React, { useEffect, useState } from "react";
import {
 Box,
 Heading,
 Text,
 Image,
 Flex,
 SimpleGrid,
 Modal,
 ModalOverlay,
 ModalContent,
 ModalHeader,
 ModalCloseButton,
 ModalBody,
 ModalFooter,
 Button,
 Drawer,
 DrawerOverlay,
 DrawerContent,
 DrawerHeader,
 DrawerBody,
 DrawerFooter,
 useBreakpointValue,
} from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const ProductDetailsPage = ({ product = {}, isOpen, onClose }) => {
 const isMobile = useBreakpointValue({ base: true, md: false });
 //  const productName = "Sample Product";
 const {} = product;
 //  console.log("product", product);

 const sliderSettings = {
  fade: true,
  speed: 2000,
  autoplaySpeed: 3000,
  infinite: true,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false,
  pauseOnHover: false,
 };

 //  const content = <></>;
 const content = (
  <Box w="100%" fontFamily="Arial, sans-serif" lineHeight="1.6" p={[3, 5, 8]}>
   {/* Header */}
   <Box textAlign="center" mb="8">
    {product?.productBannerImages?.length > 0 ? (
     <Slider {...sliderSettings}>
      {product?.productBannerImages?.map((imageUrl, index) => (
       <Image
        key={index}
        src={imageUrl}
        alt={`Product Banner ${index + 1}`}
        borderRadius="md"
       />
      ))}
     </Slider>
    ) : (
     <Text>No banner images available</Text>
    )}
    <Heading as="h1" mt="4" fontSize={["lg", "xl", "2xl"]}>
     {product?.productName}
    </Heading>
    <Text fontSize={["sm", "md", "lg"]} color="gray.500" mt="2">
     ⭐ {product?.ratings} | MRP:{" "}
     <Text as="s" display="inline">
      ₹{product?.mrp}
     </Text>{" "}
     | Price: ₹{product?.price}
    </Text>
   </Box>

   {/* Highlights */}
   <Box mb="8">
    <Heading as="h2" size="md" mb="4" fontSize={["md", "lg", "xl"]}>
     Highlights
    </Heading>
    {product?.highlights?.length > 0 ? (
     <SimpleGrid columns={[1, 2, 3]} spacing="5">
      {product?.highlights?.map((highlight, index) => (
       <Flex
        key={index}
        align="center"
        gap="2"
        bg="gray.100"
        p="4"
        borderRadius="5"
       >
        <Image
         src={
          highlight?.icon ||
          "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg"
         }
         alt={highlight?.text || "Highlight"}
         boxSize="24px"
        />
        <Text fontSize={["sm", "md"]}>
         {highlight?.text || "No description available"}
        </Text>
       </Flex>
      ))}
     </SimpleGrid>
    ) : (
     <Text>No highlights available</Text>
    )}
   </Box>
  </Box>
 );

 //  console.log("content", content);

 return isMobile ? (
  // Drawer for mobile
  <Drawer isOpen={isOpen} placement="bottom" onClose={onClose}>
   <DrawerOverlay />
   <DrawerContent
    sx={{
     height: "75vh", // 65% of the viewport height
     borderTopRadius: "md", // Add rounded corners at the top for aesthetics
    }}
   >
    <DrawerHeader>{product?.productName}</DrawerHeader>
    <DrawerBody>{content}</DrawerBody>
    <DrawerFooter>
     <Button variant="outline" onClick={onClose}>
      Close
     </Button>
    </DrawerFooter>
   </DrawerContent>
  </Drawer>
 ) : (
  // Modal for desktop
  <Modal isOpen={isOpen} onClose={onClose} size="xl">
   <ModalOverlay />
   <ModalContent>
    <ModalHeader>{product?.productName}</ModalHeader>
    <ModalCloseButton />
    <ModalBody>{content}</ModalBody>
    <ModalFooter>
     <Button variant="outline" onClick={onClose}>
      Close
     </Button>
    </ModalFooter>
   </ModalContent>
  </Modal>
 );
};

export default ProductDetailsPage;

// import React from "react";
// import { useLocation } from "react-router-dom";
// import {
//  Box,
//  Heading,
//  Text,
//  Image,
//  Flex,
//  SimpleGrid,
//  List,
//  ListItem,
//  Icon,
// } from "@chakra-ui/react";
// import { CheckCircleIcon } from "@chakra-ui/icons";

// const ProductDetailsPage = ({ product }) => {
//  const location = useLocation();
//  // const product = location.state?.product || {}; // Pass product using Link state

//  const {
//   productName = "Sample Product",
//   productBannerImage = "",
//   highlights = [],
//   includedService = [],
//   additionalServices = [],
//   ratings = 4.5,
//   mrp = 1000,
//   price = 800,
//   offerTag = [],
//  } = product;

//  return (
//   <Box
//    w={["95%", "90%", "80%"]} // Responsive width
//    margin="auto"
//    fontFamily="Arial, sans-serif"
//    lineHeight="1.6"
//    p={[3, 5, 8]} // Responsive padding
//   >
//    {/* Header */}
//    <Box textAlign="center" mb="8">
//     <Image
//      src={
//       productBannerImage ||
//       "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg"
//      }
//      alt={productName}
//      borderRadius="10px"
//      width={["100%", "100%", "80%"]} // Responsive width
//      height={["150px", "250px", "400px"]} // Responsive height
//      objectFit="cover"
//      mx="auto"
//     />
//     <Heading as="h1" mt="4" fontSize={["lg", "xl", "2xl"]}>
//      {productName}
//     </Heading>
//     <Text fontSize={["sm", "md", "lg"]} color="gray.500" mt="2">
//      ⭐ {ratings} | MRP:{" "}
//      <Text as="s" display="inline">
//       ₹{mrp}
//      </Text>{" "}
//      | Price: ₹{price}
//     </Text>
//    </Box>

//    {/* Highlights */}
//    <Box mb="8">
//     <Heading as="h2" size="md" mb="4" fontSize={["md", "lg", "xl"]}>
//      Highlights
//     </Heading>
//     <SimpleGrid columns={[1, 2, 3]} spacing="5">
//      {highlights.map((highlight, index) => (
//       <Flex
//        key={index}
//        align="center"
//        gap="2"
//        bg="gray.100"
//        p="4"
//        borderRadius="5"
//       >
//        <Image
//         src={
//          highlight.icon ||
//          "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg"
//         }
//         alt={highlight.text}
//         boxSize="24px"
//        />
//        <Text fontSize={["sm", "md"]}>{highlight.text}</Text>
//       </Flex>
//      ))}
//     </SimpleGrid>
//    </Box>

//    {/* Included Services */}
//    <Box mb="8">
//     <Heading as="h2" size="md" mb="4" fontSize={["md", "lg", "xl"]}>
//      Included Services
//     </Heading>
//     {includedService.map((serviceCategory, index) => (
//      <Box key={index} mb="6">
//       <Heading
//        as="h3"
//        size="sm"
//        mb="3"
//        fontSize={["sm", "md", "lg"]}
//        color="blue.600"
//       >
//        {serviceCategory.name}
//       </Heading>
//       <SimpleGrid columns={[2, 3, 4]} spacing="4">
//        {serviceCategory.services.map((service, idx) => (
//         <Box key={idx} textAlign="center" bg="gray.100" p="3" borderRadius="5">
//          <Image
//           src={
//            service.image ||
//            "https://img.freepik.com/free-photo/close-up-image-programer-working-his-desk-office_1098-18707.jpg"
//           }
//           alt={service.title}
//           borderRadius="5"
//           mb="2"
//           objectFit="cover"
//           height="120px"
//           width="100%"
//          />
//          <Text fontSize={["sm", "md"]}>{service.title}</Text>
//         </Box>
//        ))}
//       </SimpleGrid>
//      </Box>
//     ))}
//    </Box>

//    {/* Additional Services */}
//    <Box mb="8">
//     <Heading as="h2" size="md" mb="4" fontSize={["md", "lg", "xl"]}>
//      Additional Services
//     </Heading>
//     <List spacing="2">
//      {additionalServices.map((service, index) => (
//       <ListItem key={index} display="flex" alignItems="center" gap="2">
//        <Icon as={CheckCircleIcon} color="green.400" />
//        <Text fontSize={["sm", "md"]}>{service}</Text>
//       </ListItem>
//      ))}
//     </List>
//    </Box>

//    {/* Special Offers */}
//    <Box mb="8">
//     <Heading as="h2" size="md" mb="4" fontSize={["md", "lg", "xl"]}>
//      Special Offers
//     </Heading>
//     <SimpleGrid columns={[1, 2, 3]} spacing="4">
//      {offerTag.map((offer, index) => (
//       <Box
//        key={index}
//        border="1px solid"
//        borderColor="gray.200"
//        p="3"
//        borderRadius="5"
//        textAlign="center"
//       >
//        🎉 {offer.value}
//       </Box>
//      ))}
//     </SimpleGrid>
//    </Box>
//   </Box>
//  );
// };

// export default ProductDetailsPage;
