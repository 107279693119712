import React, { useEffect, useState } from "react";
import {
 Box,
 Heading,
 Text,
 VStack,
 HStack,
 Image,
 Divider,
 Stack,
 RadioGroup,
 Radio,
 Button,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import {
 fetchBookingFee,
 fetchCart,
 fetchCODVisibility,
} from "../../redux/cartSlice";
import { useLocation } from "react-router-dom";

// If you’re using direct axios calls:
// import {
//  getBookingFeeFromServer,
//  getCODVisibilityFromServer,
// } from "../../api/bookingFeeAPI"; // adjust path

// If you have your createOrder logic as a thunk, import it
// Otherwise, we'll just show a console.log example
// import { createOrderThunk } from "../../redux/orderSlice";

function CartSummaryPage() {
 const location = useLocation();
 const { state } = location; // state.address, etc. if needed
 const dispatch = useDispatch();
 const { user } = useSelector((s) => s.auth);
 const { cart } = useSelector((s) => s.cart);

 // 1) Local states for booking fee & COD
 const [bookingFee, setBookingFee] = useState(0);
 const [showCOD, setShowCOD] = useState(true); // assume true if not fetched yet

 // 2) Payment method choice
 const [paymentMethod, setPaymentMethod] = useState("Full Payment");

 // 3) Fetch cart
 useEffect(() => {
  if (user?._id) {
   dispatch(fetchCart(user._id));
  }
 }, [dispatch, user]);

 // 4) Fetch booking fee & COD visibility
 useEffect(() => {
  async function fetchSettings() {
   try {
    const bfData = await dispatch(fetchBookingFee()).unwrap(); // { amount: 200 } for example
    setBookingFee(bfData || 0);
    console.log("bfData:", bfData);
    const codData = await dispatch(fetchCODVisibility()).unwrap(); // { showCOD: true }
    setShowCOD(codData);
    console.log("codData:", codData);
   } catch (err) {
    console.error("Error fetching booking fee/COD:", err);
   }
  }
  fetchSettings();
 }, []);

 if (!cart) {
  return (
   <Box p={6}>
    <Text>Loading cart data...</Text>
   </Box>
  );
 }

 const {
  userId,
  items = [],
  totalItems,
  totalPrice,
  finalPrice,
  couponCode,
  couponDiscount = 0,
  slotDiscount = 0,
  referralDiscount = 0,
 } = cart;

 // 5) Calculate dynamic breakdown based on paymentMethod
 // By default we assume:
 // Full Payment => user pays finalPrice
 // Booking Fee => user pays bookingFee upfront, with (finalPrice - bookingFee) as balanceDue
 // COD => user pays finalPrice on delivery

 const finalPriceNumber = parseFloat(finalPrice) || 0;
 const bookingFeeNumber = parseFloat(bookingFee) || 0;

 let amountPayableNow = finalPriceNumber; // for "Full Payment"
 let balanceDue = 0;

 if (paymentMethod === "Booking Fee") {
  amountPayableNow = bookingFeeNumber;
  balanceDue = Math.max(0, finalPriceNumber - bookingFeeNumber);
 } else if (paymentMethod === "COD") {
  amountPayableNow = 0; // paid on delivery
  balanceDue = finalPriceNumber;
 }

 // 6) Create Order Handler
 const handleCreateOrder = async () => {
  const userIdParam = userId?._id || userId; // depending how your cart stored it
  if (!userIdParam) {
   alert("No userId found.");
   return;
  }
  // Example: gather data to send
  const orderPayload = {
   slotId: state?.slotId || null, // if you stored slot in state
   date: state?.selectedDate || null,
   paymentMethod,
   carId: user.currentCar?._id,
   addressId: state?.address?.addressId, // if user selected existing
   newAddress: state?.address?.type === "NEW" ? state.address : null,
  };

  // For demonstration, console.log instead of actual call
  console.log("Creating order with:", orderPayload);

  // If you have a createOrder thunk or direct axios call:
  /*
      try {
        const response = await dispatch(createOrderThunk({ userId: userIdParam, ...orderPayload }));
        // or direct axios: await axios.post(`/api/orders/${userIdParam}`, orderPayload);
        console.log("Order created:", response);
      } catch (err) {
        console.error("Error creating order:", err);
      }
    */
 };

 return (
  <Box
   maxW="800px"
   mx="auto"
   mt={8}
   p={6}
   borderWidth={1}
   borderRadius="md"
   bg="white"
  >
   <Heading as="h2" size="lg" mb={4}>
    Cart Summary
   </Heading>

   {/* Basic User Info */}
   {userId && userId._id && (
    <Box mb={4}>
     <Text>
      <strong>User ID:</strong> {userId._id}
     </Text>
     <Text>
      <strong>Mobile:</strong> {userId.mobile}
     </Text>
    </Box>
   )}

   <Divider my={4} />

   {/* Items in Cart */}
   <Heading as="h3" size="md" mb={2}>
    Items in Cart ({totalItems})
   </Heading>
   <VStack align="stretch" spacing={4}>
    {items.map((item) => {
     const isProduct = item.type === "Product";
     const productName = isProduct
      ? item.productDetails?.productName
      : item.productDetails?.spareName;
     const productImage = isProduct
      ? item.productDetails?.productImage
      : item.productDetails?.image;

     return (
      <Box key={item._id} p={4} borderWidth={1} borderRadius="md" bg="gray.50">
       <HStack spacing={4} align="flex-start">
        <Image
         src={productImage}
         alt={productName}
         boxSize="80px"
         objectFit="cover"
         fallbackSrc="https://via.placeholder.com/80"
        />
        <VStack align="start" spacing={1}>
         <Text>
          <strong>Type:</strong> {item.type}
         </Text>
         <Text>
          <strong>Product Name:</strong> {productName}
         </Text>
         <Text>
          <strong>Quantity:</strong> {item.quantity}
         </Text>
         <Text>
          <strong>Price per unit:</strong> ₹{item.price}
         </Text>
         <Text>
          <strong>Total Price:</strong> ₹{item.totalPrice}
         </Text>
        </VStack>
       </HStack>
      </Box>
     );
    })}
   </VStack>

   <Divider my={6} />

   {/* Payment Options */}
   <Heading as="h4" size="md" mb={2}>
    Payment Options
   </Heading>

   <RadioGroup onChange={setPaymentMethod} value={paymentMethod}>
    <VStack align="start" spacing={2}>
     {/* Full Payment always available */}
     <Radio value="Full Payment">Full Payment</Radio>

     {/* Booking Fee only if > 0 */}
     {bookingFee > 0 && (
      <Radio value="Booking Fee">Booking Fee (₹{bookingFee})</Radio>
     )}

     {/* COD only if showCOD is true */}
     {showCOD ? (
      <Radio value="COD">Cash on Delivery (COD)</Radio>
     ) : (
      <Text fontSize="sm" color="gray.500">
       COD not available
      </Text>
     )}
    </VStack>
   </RadioGroup>

   <Divider my={6} />

   {/* Pricing Details */}
   <Heading as="h3" size="md" mb={2}>
    Pricing Details
   </Heading>
   <Stack spacing={2} mb={4}>
    <HStack justify="space-between">
     <Text>Subtotal:</Text>
     <Text>₹{totalPrice}</Text>
    </HStack>

    {/* If a coupon was applied */}
    {couponCode && (
     <HStack justify="space-between">
      <Text>Coupon ({couponCode}):</Text>
      <Text>- ₹{couponDiscount}</Text>
     </HStack>
    )}

    {/* If a slot discount applies */}
    {slotDiscount > 0 && (
     <HStack justify="space-between">
      <Text>Slot Discount:</Text>
      <Text>- ₹{slotDiscount}</Text>
     </HStack>
    )}

    {/* If a referral discount applies */}
    {referralDiscount > 0 && (
     <HStack justify="space-between">
      <Text>Referral Discount:</Text>
      <Text>- ₹{referralDiscount}</Text>
     </HStack>
    )}

    <HStack justify="space-between">
     <Text fontWeight="bold">Payment Method:</Text>
     <Text>{paymentMethod}</Text>
    </HStack>

    {paymentMethod === "Booking Fee" && (
     <>
      <HStack justify="space-between">
       <Text>Booking Fee:</Text>
       <Text>₹{bookingFeeNumber}</Text>
      </HStack>
      <HStack justify="space-between">
       <Text>Balance Due Later:</Text>
       <Text>₹{balanceDue.toFixed(2)}</Text>
      </HStack>
     </>
    )}

    {paymentMethod === "COD" && (
     <HStack justify="space-between">
      <Text>Balance Due on Delivery:</Text>
      <Text>₹{balanceDue.toFixed(2)}</Text>
     </HStack>
    )}

    <Divider />

    {/* Final Price & Payment Now */}
    <HStack justify="space-between" fontWeight="bold">
     <Text>Final Price:</Text>
     <Text>₹{finalPriceNumber.toFixed(2)}</Text>
    </HStack>
    <HStack justify="space-between" fontWeight="bold">
     <Text>Pay Now:</Text>
     <Text>₹{amountPayableNow.toFixed(2)}</Text>
    </HStack>
   </Stack>

   {/* CTA: Create Order */}
   <Box textAlign="center">
    <Button colorScheme="red" onClick={handleCreateOrder}>
     Proceed to Create Order
    </Button>
   </Box>
  </Box>
 );
}

export default CartSummaryPage;

// import React, { useEffect } from "react";
// import {
//  Box,
//  Heading,
//  Text,
//  VStack,
//  HStack,
//  Image,
//  Divider,
//  Stack,
// } from "@chakra-ui/react";
// import { useDispatch, useSelector } from "react-redux";
// import { fetchCart } from "../../redux/cartSlice";
// import { useLocation } from "react-router-dom";

// function CartSummaryPage() {
//  const location = useLocation();
//  const { state } = location;
//  const dispatch = useDispatch();
//  const { user } = useSelector((state) => state.auth);
//  // 1) Grab the cart from Redux (or from props if you prefer)
//  const { cart } = useSelector((state) => state.cart);
//  //  console.log("cart:", cart ? true : false);
//  //  console.log("user:", user._id);
//  //  console.log("state:", state);
//  useEffect(() => {
//   const userId = user?._id;
//   dispatch(fetchCart(userId));
//  }, [dispatch]);
//  //  const extractNestedData = (obj, prefix = "") => {
//  //   for (const [key, value] of Object.entries(obj)) {
//  //    if (typeof value === "object" && value !== null) {
//  //     extractNestedData(value, `${prefix}${key}.`);
//  //    } else {
//  //     console.log(`${prefix}${key}: ${value}`);
//  //    }
//  //   }
//  //  };

//  //  extractNestedData(location);

//  // if()
//  // 2) If the cart isn’t loaded yet, show a placeholder
//  if (!cart) {
//   return (
//    <Box p={6}>
//     <Text>Loading cart data...</Text>
//    </Box>
//   );
//  }

//  // 3) Destructure the fields from cart
//  const {
//   userId,
//   items = [],
//   totalItems,
//   totalPrice,
//   finalPrice,
//   discount,
//   couponCode,
//   couponDiscount,
//   slotDiscount,
//   referralDiscount,
//  } = cart;
//  console.log("cart:", cart);

//  return (
//   <Box
//    maxW="800px"
//    mx="auto"
//    mt={8}
//    p={6}
//    borderWidth={1}
//    borderRadius="md"
//    bg="white"
//   >
//    {/* Cart Title */}
//    <Heading as="h2" size="lg" mb={4}>
//     Cart Summary
//    </Heading>

//    {/* Basic User Info (Optional) */}
//    {userId && (
//     <Box mb={4}>
//      <Text>
//       <strong>User ID:</strong> {userId._id}
//      </Text>
//      <Text>
//       <strong>Mobile:</strong> {userId.mobile}
//      </Text>
//     </Box>
//    )}

//    <Divider my={4} />

//    {/* Items Section */}
//    <Heading as="h3" size="md" mb={2}>
//     Items in Cart ({totalItems})
//    </Heading>

//    {/* Map over cart items */}
//    <VStack align="stretch" spacing={4}>
//     {items.map((item) => {
//      const isProduct = item.type === "Product";
//      const productName = isProduct
//       ? item.productDetails?.productName
//       : item.productDetails?.spareName;
//      const productImage = isProduct
//       ? item.productDetails?.productImage
//       : item.productDetails?.image;
//      return (
//       <Box key={item._id} p={4} borderWidth={1} borderRadius="md" bg="gray.50">
//        <HStack spacing={4} align="flex-start">
//         {/* Product/Spare Image */}
//         <Image
//          src={productImage}
//          alt={productName}
//          boxSize="80px"
//          objectFit="cover"
//          fallbackSrc="https://via.placeholder.com/80"
//         />

//         <VStack align="start" spacing={1}>
//          <Text>
//           <strong>Type:</strong> {item.type}
//          </Text>
//          <Text>
//           <strong>Product Name:</strong> {productName}
//          </Text>
//          <Text>
//           <strong>Quantity:</strong> {item.quantity}
//          </Text>
//          <Text>
//           <strong>Price per unit:</strong> ₹{item.price}
//          </Text>
//          <Text>
//           <strong>Total Price:</strong> ₹{item.totalPrice}
//          </Text>
//         </VStack>
//        </HStack>
//       </Box>
//      );
//     })}
//    </VStack>

//    <Divider my={6} />

//    {/* Pricing & Discounts Section */}
//    <Heading as="h3" size="md" mb={2}>
//     Pricing Details
//    </Heading>
//    <Stack spacing={2} mb={4}>
//     <HStack justify="space-between">
//      <Text>Subtotal:</Text>
//      <Text>₹{totalPrice}</Text>
//     </HStack>

//     {/* If a coupon was applied */}
//     {couponCode && (
//      <HStack justify="space-between">
//       <Text>Coupon ({couponCode}):</Text>
//       <Text>- ₹{couponDiscount || 0}</Text>
//      </HStack>
//     )}

//     {/* If a slot discount applies */}
//     {slotDiscount > 0 && (
//      <HStack justify="space-between">
//       <Text>Slot Discount:</Text>
//       <Text>- ₹{slotDiscount}</Text>
//      </HStack>
//     )}

//     {/* If a referral discount applies */}
//     {referralDiscount > 0 && (
//      <HStack justify="space-between">
//       <Text>Referral Discount:</Text>
//       <Text>- ₹{referralDiscount}</Text>
//      </HStack>
//     )}
//     <HStack justify="space-between">
//      <Text>Total Discount:</Text>
//      <Text>₹{couponDiscount ? couponDiscount + slotDiscount : 0}</Text>
//     </HStack>
//     <Divider />

//     <HStack justify="space-between" fontWeight="bold">
//      <Text>Final Price:</Text>
//      <Text>₹{finalPrice?.toFixed(2)}</Text>
//     </HStack>
//    </Stack>

//    {/* Place your “Proceed to Create Order” or final CTA here */}
//    <Box textAlign="center">
//     <button
//      style={{
//       backgroundColor: "red",
//       color: "#fff",
//       padding: "8px 16px",
//       borderRadius: "4px",
//       cursor: "pointer",
//      }}
//      onClick={() => alert("Create Order logic goes here.")}
//     >
//      Proceed to Create Order
//     </button>
//    </Box>
//   </Box>
//  );
// }

// export default CartSummaryPage;
