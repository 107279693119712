import Routers from "./routers/Routers";
import { Box } from "@chakra-ui/react";
import "./App.css";
import Navbar from "./Components/Header/Navbar";
import Footer from "./Components/Footer/Footer";
import { useEffect, useState } from "react";
import UniversalLoader from "./Components/pages/UniversalLoader";

function App() {
 const [isLoading, setIsLoading] = useState(true);

 useEffect(() => {
  // Simulate a 2-second loading phase
  const timer = setTimeout(() => {
   setIsLoading(false);
  }, 1000);

  // Clear the timer to avoid memory leaks
  return () => clearTimeout(timer);
 }, []);
 return (
  <Box
  //    sx={{
  //     width: {
  //            xs: "95%",
  //            // 95% of viewport width for small screens
  //      sm: "95%",
  //      md: "70vw",
  //     },
  //        maxWidth: "1200px",
  //        // Optional max width to prevent excessive stretching
  //     margin: "0 auto",
  //     padding: 2,
  //    }}
  >
   {isLoading && <UniversalLoader />}
   {!isLoading && <Navbar />}
   {!isLoading && <Routers />}
   {!isLoading && <Footer />}
  </Box>
 );
}

export default App;
