import React, { useState, useEffect } from "react";
import {
 Box,
 Flex,
 Text,
 Heading,
 Button,
 VStack,
 IconButton,
 Divider,
} from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";
import {
 format,
 addDays,
 startOfWeek,
 parse,
 isBefore,
 isSameDay,
 startOfToday,
} from "date-fns";

// 1) Import the useNavigate hook from react-router-dom (if using React Router)
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookSlotThunk } from "../../redux/cartSlice";

const TimeSlotPicker = () => {
 const navigate = useNavigate(); // 2) Initialize the hook
 const dispatch = useDispatch();
 const [slots, setSlots] = useState([]);
 const [selectedDate, setSelectedDate] = useState(new Date());
 const [selectedTime, setSelectedTime] = useState(null);
 const { user } = useSelector((state) => state.auth);

 useEffect(() => {
  fetch("https://app-api.carexpert.org.in/api/slots")
   .then((res) => res.json())
   .then((data) => setSlots(data))
   .catch((err) => console.error("Error fetching slots:", err));
 }, []);

 const today = startOfToday();
 const startDate = startOfWeek(selectedDate < today ? today : selectedDate, {
  weekStartsOn: 1,
 });
 const daysOfWeek = Array.from({ length: 7 }, (_, i) => addDays(startDate, i));

 const handlePreviousWeek = () => {
  const previousWeek = addDays(selectedDate, -7);
  if (previousWeek < today) {
   setSelectedDate(today);
  } else {
   setSelectedDate(previousWeek);
  }
 };

 const handleNextWeek = () => {
  setSelectedDate((prev) => addDays(prev, 7));
 };

 // 3) Handler for the “Next” button
 const handleNextClick = () => {
  console.log("selectedTime:", selectedTime);
  // Replace "/next-route" with the route you want to navigate to
  navigate("/address");
 };
 const handleBookSlot = (slot) => {
  console.log("user:", user);
  const userId = user._id;
  const slotId = slot;
  const city = "tamkuhi";
  const date = selectedDate;

  setSelectedTime(slot);
  dispatch(bookSlotThunk({ userId, slotId, city, date }));
  console.log("slot:", slot);
  console.log("userId:", userId);
  console.log("slotId:", slotId);
  console.log("city:", city);
  console.log("date:", date);
 };

 return (
  <Box
   maxW={{ base: "95%", md: "80%", lg: "60%" }}
   mx="auto"
   h={{ base: "auto", md: "70vh" }}
   p={{ base: 4, md: 6, lg: 8 }}
   borderWidth={1}
   borderRadius="lg"
   shadow="lg"
   bg="white"
   mt={4}
  >
   <Heading
    as="h3"
    size={{ base: "md", md: "lg", lg: "xl" }}
    textAlign="center"
    mb={4}
   >
    📅 Schedule Your Slot at Your Convenience
   </Heading>

   {/* Week Selector */}
   <Flex
    direction={{ base: "column", md: "row" }}
    justify={{ base: "center", md: "space-between" }}
    align="center"
    mb={4}
    gap={{ base: 2, md: 4 }}
   >
    <IconButton
     icon={<ChevronLeftIcon />}
     aria-label="Previous week"
     onClick={handlePreviousWeek}
    />

    <Flex
     direction="row"
     wrap="wrap"
     justify="center"
     align="center"
     gap={{ base: 2, md: 4 }}
    >
     {daysOfWeek.map((date, index) => {
      if (date < today) return null; // skip old dates

      const isSelected = isSameDay(date, selectedDate);
      return (
       <VStack
        key={index}
        p={2}
        cursor="pointer"
        onClick={() => setSelectedDate(date)}
        bg={isSelected ? "purple.500" : "transparent"}
        borderRadius="md"
       >
        <Text fontWeight="bold" color={isSelected ? "white" : "black"}>
         {format(date, "EEE")}
        </Text>
        <Text color={isSelected ? "white" : "black"}>
         {format(date, "d MMM")}
        </Text>
       </VStack>
      );
     })}
    </Flex>

    <IconButton
     icon={<ChevronRightIcon />}
     aria-label="Next week"
     onClick={handleNextWeek}
    />
   </Flex>

   {/* Time Zone (Optional) */}
   <Text mb={2} fontWeight="bold" textAlign="center">
    Local Time ({format(new Date(), "HH:mm")})
   </Text>
   <Divider mb={4} />

   {/* Time Slots */}
   <VStack spacing={2}>
    <Flex direction="row" wrap="wrap" gap={{ base: 2, md: 4 }} justify="center">
     {slots.map((slot) => {
      const slotDateTime = parse(slot.time, "h:mm a", selectedDate);
      const isSlotPast = isBefore(slotDateTime, new Date());

      let label = slot.time;
      if (slot.discount) {
       label += ` (discount ${slot.discount}%)`;
      }

      return (
       <Button
        key={slot._id}
        variant={selectedTime === slot._id ? "solid" : "outline"}
        colorScheme={selectedTime === slot._id ? "purple" : "gray"}
        onClick={() => handleBookSlot(slot._id)}
        // isDisabled={isSlotPast}
       >
        {label}
       </Button>
      );
     })}
    </Flex>
   </VStack>

   {/* 4) Conditionally show the Next button once a time is selected */}
   {selectedTime && (
    <Flex justify="center" mt={4}>
     <Button colorScheme="blue" onClick={handleNextClick}>
      Next
     </Button>
    </Flex>
   )}
  </Box>
 );
};

export default TimeSlotPicker;
