import React, { useState } from "react";
import { Box, Button, IconButton, Flex } from "@chakra-ui/react";
import { PhoneIcon } from "@chakra-ui/icons";
import { FaWhatsapp } from "react-icons/fa";

const FloatingButton = () => {
 const [showOptions, setShowOptions] = useState(false);

 // Function to handle mobile call
 const handleCallMobile = () => {
  window.open("tel:9999504703");
 };

 // Function to handle WhatsApp message
 const handleCallWhatsApp = () => {
  window.open("https://wa.me/9999504703");
 };

 return (
  <Box position="fixed" bottom="20px" right="20px" zIndex={1000}>
   {/* Main Floating Button */}
   <IconButton
    size="lg"
    colorScheme="blue"
    icon={<PhoneIcon />}
    onClick={() => setShowOptions((prev) => !prev)}
    boxShadow="lg"
   />

   {/* Additional Buttons */}
   {showOptions && (
    <Flex direction="column" alignItems="center" gap="10px" mt="10px">
     <Button
      colorScheme="green"
      leftIcon={<PhoneIcon />}
      onClick={handleCallMobile}
     >
      Call Mobile
     </Button>
     <Button
      colorScheme="whatsapp"
      leftIcon={<FaWhatsapp />}
      onClick={handleCallWhatsApp}
     >
      Call WhatsApp
     </Button>
    </Flex>
   )}
  </Box>
 );
};

export default FloatingButton;
