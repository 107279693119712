import axios from "axios";
// import store from "./store"; // Import your Redux store
// import { logout } from "../features/auth/authSlice"; // Import the logout action

const axiosInstance = axios.create({
 baseURL: "https://app-api.carexpert.org.in/api", // Replace with your API base URL
});

// Add a response interceptor
axiosInstance.interceptors.response.use(
 (response) => response, // Pass successful responses
 (error) => {
  if (error.response?.status === 401) {
   // Token expired or unauthorized
   console.error("Token expired. Logging out...");
   //    store.dispatch(logout()); // Dispatch the logout action
  }
  return Promise.reject(error);
 }
);

export default axiosInstance;
