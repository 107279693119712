import React, { useState } from "react";
import { Box, IconButton, Badge, Text, HStack } from "@chakra-ui/react";
import { FiShoppingCart } from "react-icons/fi";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

export const CartButton = ({ quantity = 0, onClick }) => {
 const { cart } = useSelector((state) => state.cart);

 return (
  <Box position="relative">
   <Link to={"/product-listing-page"}>
    <IconButton
     aria-label="Cart"
     icon={<FiShoppingCart />}
     fontSize="1rem"
     bg="blue.500"
     color="white"
     _hover={{ bg: "blue.600" }}
     _active={{ bg: "blue.700" }}
     borderRadius="full"
     onClick={onClick}
     size="sm"
    />
   </Link>
   {/* Quantity Badge */}
   {quantity > 0 && (
    <Badge
     position="absolute"
     top="-2"
     right="-2"
     bg="red.500"
     color="white"
     borderRadius="full"
     fontSize="xs"
     px={2}
    >
     {cart?.totalItems}
    </Badge>
   )}
  </Box>
 );
};
