import { Box } from "@chakra-ui/react";
import React, { Suspense, lazy } from "react";
// import  from "../UI/TrandingProducts";
// import  from "";
const HeroSlider = lazy(() => import("../UI/HeroSlider"));
const ServicesPage = lazy(() => import("../UI/ServiceSection/ServicesPage"));
const BrandSlider = lazy(() => import("./BrandSlider"));
const CompanySlider = lazy(() => import("../Home/CompanySlider"));
const UserReviewsSlider = lazy(() => import("../Home/UserReviewsSlider"));
const TestimonialVideoSlider = lazy(() =>
 import("../Home/VideoTestimonialsSlider")
);
const PartnerReviewsSlider = lazy(() => import("../Home/PartnerReviewsSlider"));
const FaqAccordion = lazy(() => import("../UI/FaqAccordion"));

const Home = () => {
 return (
  //   <Box border={"10px solid black"} w="80%">
  <Suspense>
   <HeroSlider />
   <ServicesPage />
   <BrandSlider />
   <CompanySlider />
   <UserReviewsSlider />
   <TestimonialVideoSlider />
   <PartnerReviewsSlider />
   <FaqAccordion />
  </Suspense>
  //   </Box>
 );
};

export default Home;

// // import React, { useEffect, useRef } from "react";
// // import HeroSlider from "../UI/HeroSlider";
// // import Helmet from "../Helmet/Helmet";
// // import AboutSection from "../UI/AboutSection";
// // import ServicesPage from "../UI/ServiceSection/ServicesPage";
// // import { Grid, GridItem, Box } from "@chakra-ui/react";
// // import { BrandSlider } from "./BrandSlider";
// // import CompanySlider from "../Home/CompanySlider";
// // import UserReviewsSlider from "../Home/UserReviewsSlider";
// // import { useDispatch, useSelector } from "react-redux";
// // import {
// //  fetchPartnerReviews,
// //  fetchUserReviews,
// //  fetchVideoTestimonials,
// // } from "../../redux/reviewsSlice";
// // import TestimonialVideoSlider from "../Home/VideoTestimonialsSlider";
// // import PartnerReviewsSlider from "../Home/PartnerReviewsSlider";

// // const Home = () => {
// //  const servicesRef = useRef(null);
// //  const dispatch = useDispatch();
// //  const { partnerReviews, userReviews, videoTestimonials, loading, error } =
// //   useSelector((state) => state.reviews);

// //  useEffect(() => {
// //   dispatch(fetchPartnerReviews());
// //   dispatch(fetchUserReviews());
// //   dispatch(fetchVideoTestimonials());
// //  }, [dispatch]);

// //  return (
// //   <Grid
// //    templateAreas={{
// //     base: `"main"
// //            "servicePage"
// //            "brand"
// //            "about"`,
// //     md: `"main"
// //          "servicePage"
// //          "brand"
// //          "about about"`,
// //     lg: `"main"
// //          "servicePage"
// //          "about"`,
// //    }}
// //    gridTemplateRows={{
// //     base: "auto auto auto auto",
// //     md: "auto auto auto auto",
// //     lg: "auto auto auto",
// //    }}
// //    gridTemplateColumns={{
// //     base: "1fr",
// //     md: "1fr",
// //     lg: "1fr",
// //    }}
// //    h="auto"
// //    w="100%"
// //    m={"auto"}
// //   >
// //    <Helmet title="Home">
// //     {/* Main Hero Slider */}
// //     <GridItem area="main">
// //      <Box>
// //       <HeroSlider />
// //      </Box>
// //     </GridItem>

// //     {/* Services Section */}
// //     <GridItem area="servicePage">
// //      <Box>
// //       <ServicesPage />
// //      </Box>
// //     </GridItem>

// //     {/* Car Brand Section */}
// //     <GridItem area="brand">
// //      <Box>
// //       <BrandSlider />
// //      </Box>
// //     </GridItem>

// //     {/* About Section */}

// //     <GridItem area="about">
// //      <Box>
// //       <CompanySlider />
// //      </Box>
// //     </GridItem>
// //     <GridItem area="about">
// //      <Box>
// //       <UserReviewsSlider userReviews={userReviews} />
// //      </Box>
// //     </GridItem>
// //     <GridItem area="about">
// //      <Box>
// //       <TestimonialVideoSlider />
// //      </Box>
// //     </GridItem>
// //     <GridItem area="about">
// //      <Box>
// //       <PartnerReviewsSlider />
// //      </Box>
// //     </GridItem>
// //    </Helmet>
// //   </Grid>
// //  );
// // };

// // export default Home;
