import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

// Fetch trending products
export const fetchTrendingProducts = createAsyncThunk(
 "trending/fetchTrendingProducts",
 async () => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/products/trending"
  );
  const trendingProducts = response.data;

  return trendingProducts;
 }
);
export const fetchSparePartsProducts = createAsyncThunk(
 "trending/fetchSparePartsProducts",
 async () => {
  const response = await axios.get(
   "https://app-api.carexpert.org.in/api/spareparts"
  );
  const spareParts = response.data;

  return spareParts;
 }
);
const trendingSlice = createSlice({
 name: "trending",
 initialState: {
  trendingProducts: [], // State for trending products
  spareParts: [],
  loading: false,
  error: null,
 },
 reducers: {},
 extraReducers: (builder) => {
  builder
   // Handle trending products
   .addCase(fetchTrendingProducts.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchSparePartsProducts.pending, (state) => {
    state.loading = true;
   })
   .addCase(fetchTrendingProducts.fulfilled, (state, action) => {
    state.loading = false;
    state.trendingProducts = action.payload;
   })
   .addCase(fetchSparePartsProducts.fulfilled, (state, action) => {
    state.loading = false;
    state.spareParts = action.payload;
   })
   .addCase(fetchTrendingProducts.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   })
   .addCase(fetchSparePartsProducts.rejected, (state, action) => {
    state.loading = false;
    state.error = action.error.message;
   });
 },
});

export default trendingSlice.reducer;
