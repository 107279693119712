/** @jsxImportSource @emotion/react */
import React from "react";
import { Box, Text, Spinner } from "@chakra-ui/react";
import { keyframes } from "@emotion/react";

// Define keyframes for the animation
const bounce = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-15px);
  }
`;

const UniversalLoader = () => {
 return (
  <Box
   height="150vh"
   display="flex"
   flexDirection="column"
   justifyContent="center"
   alignItems="center"
   bgGradient="linear(to-b, blue.50, blue.100)"
  >
   {/* Bouncing Dots */}
   <Box display="flex" justifyContent="center" alignItems="center" mb={6}>
    {[...Array(3)].map((_, i) => (
     <Box
      key={i}
      w="12px"
      h="12px"
      mx="4px"
      bg="blue.500"
      borderRadius="full"
      animation={`${bounce} 1.5s ease-in-out ${i * 0.2}s infinite`}
     ></Box>
    ))}
   </Box>

   {/* Spinner */}
   {/* <Spinner size="xl" color="blue.500" mb={6} /> */}

   {/* Loading Text */}
   {/* <Text fontSize="lg" color="gray.600">
    Fetching data, please wait...
   </Text> */}
  </Box>
 );
};

export default UniversalLoader;
