import React, { useState } from "react";
import {
 Box,
 Heading,
 Text,
 Input,
 Grid,
 RadioGroup,
 Radio,
 Select,
 FormControl,
 FormLabel,
 Button,
 Stack,
 Divider,
 VStack,
 FormErrorMessage,
 Textarea,
 useToast,
 HStack,
 useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { createFormEntry } from "../../redux/FranchiseformSlice";
import DialogTemplate from "./DialogTemplate";
import FranchiseDetails from "./FranchiseDetails";

const steps = [
 "Personal Details",
 "Business Details",
 "Banking Details",
 "Franchise Details",
 "Create User & Document Upload",
 "Payment",
];
const stepFields = [
 [
  "firstName",
  "lastName",
  "dob",
  "gender",
  "contactNumber",
  "email",
  "address",
  "panNumber",
  "documentType",
  "documentNumber",
 ],
 [
  "companyName",
  "companyType",
  "companyAddress",
  "businessContactNumber",
  "businessDocumentType",
  "businessDocumentNumber",
 ],
 [
  "accountName",
  "accountNumber",
  "ifscCode",
  "accountType",
  "branchName",
  "bankName",
 ],
 [
  "franchiseType",
  "franchiseApplicantName",
  "franchisePincode",
  "franchiseAreaName",
  "franchiseCity",
  "franchiseRadius",
  "investmentAmount",
  "registrationAmount",
  "pendingAmount",
  "qualityParameter",
 ],
 [
  "username",
  "userEmail",
  "password",
  "currentProfession",
  "franchiseReason",
  "panUpload",
  "aadharUpload",
  "businessDocUpload",
  "bankProofUpload",
  "photoUpload",
 ],
 [], // Payment step has no fields to validate
];
const registrationAmount = 15000;
const stepContent = (
 register,
 errors,
 setValue,
 investmentAmount,
 setInvestmentAmount,
 pendingAmount
) => [
 {
  label: "Personal Details",
  content: (
   <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
    <FormControl isInvalid={errors.firstName} isRequired>
     <FormLabel>First Name</FormLabel>
     <Input
      placeholder="First Name"
      {...register("firstName", { required: "First Name is required" })}
     />
     <FormErrorMessage>
      {errors.firstName && errors.firstName.message}
     </FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={errors.lastName} isRequired>
     <FormLabel>Last Name</FormLabel>
     <Input
      placeholder="Last Name"
      {...register("lastName", { required: "Last Name is required" })}
     />
     <FormErrorMessage>
      {errors.lastName && errors.lastName.message}
     </FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={errors.dob} isRequired>
     <FormLabel>Date of Birth</FormLabel>
     <Input
      type="date"
      placeholder="Date of Birth"
      {...register("dob", { required: "Date of Birth is required" })}
     />
     <FormErrorMessage>{errors.dob && errors.dob.message}</FormErrorMessage>
    </FormControl>
    <FormControl as="fieldset" isRequired>
     <FormLabel>Gender</FormLabel>
     <RadioGroup defaultValue="male">
      <Stack direction="row">
       <Radio {...register("gender")} value="male">
        Male
       </Radio>
       <Radio {...register("gender")} value="female">
        Female
       </Radio>
      </Stack>
     </RadioGroup>
    </FormControl>
    <FormControl isInvalid={errors.contactNumber} isRequired>
     <FormLabel>Contact Number</FormLabel>
     <Input
      placeholder="Contact Number"
      {...register("contactNumber", { required: "Contact Number is required" })}
     />
     <FormErrorMessage>
      {errors.contactNumber && errors.contactNumber.message}
     </FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={errors.email} isRequired>
     <FormLabel>Email Address</FormLabel>
     <Input
      placeholder="Email Address"
      {...register("email", {
       required: "Email is required",
       pattern: { value: /\S+@\S+\.\S+/, message: "Invalid email address" },
      })}
     />
     <FormErrorMessage>{errors.email && errors.email.message}</FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={errors.address} isRequired>
     <FormLabel>Address</FormLabel>
     <Input
      placeholder="Address"
      {...register("address", { required: "Address is required" })}
     />
     <FormErrorMessage>
      {errors.address && errors.address.message}
     </FormErrorMessage>
    </FormControl>
    <FormControl isInvalid={errors.panNumber} isRequired>
     <FormLabel>PAN Number</FormLabel>
     <Input
      placeholder="Pan Number"
      {...register("panNumber", { required: "PAN Number is required" })}
     />
     <FormErrorMessage>
      {errors.panNumber && errors.panNumber.message}
     </FormErrorMessage>
    </FormControl>
    <FormControl isRequired>
     <FormLabel>Document Type</FormLabel>
     <Select {...register("documentType")} placeholder="Select option">
      <option value="aadhar">Aadhar</option>
      <option value="voterid">Voter ID</option>
      <option value="dl">Driving License</option>
     </Select>
    </FormControl>
    <FormControl>
     <FormLabel>Document Number</FormLabel>
     <Input placeholder="Document Number" {...register("documentNumber")} />
    </FormControl>
   </Grid>
  ),
 },
 {
  label: "Business Details",
  content: (
   <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
    <FormControl>
     <FormLabel>Company Name</FormLabel>

     <Input placeholder="Company Name" {...register("companyName")} />
    </FormControl>
    <FormControl>
     <FormLabel>Company Type</FormLabel>
     <Select {...register("companyType")} placeholder="Select company type">
      <option value="pvtltd">Pvt Ltd</option>
      <option value="limited">Limited</option>
      <option value="partnership">Partnership</option>
      <option value="sole">Sole Proprietor</option>
     </Select>
    </FormControl>
    <FormControl>
     <FormLabel>Company Address</FormLabel>

     <Input placeholder="Company Address" {...register("companyAddress")} />
    </FormControl>
    <FormControl>
     <FormLabel>Contact Number</FormLabel>

     <Input
      placeholder="Contact Number"
      {...register("businessContactNumber")}
     />
    </FormControl>
    <FormControl>
     <FormLabel>Document Type</FormLabel>
     <Select
      {...register("businessDocumentType")}
      placeholder="Select document type"
     >
      <option value="gst">GST Certificate</option>
      <option value="coi">COI</option>
      <option value="msme">MSME Certificate</option>
      <option value="other">Other</option>
     </Select>
    </FormControl>
    <FormControl>
     <FormLabel>Document Number</FormLabel>
     <Input
      placeholder="Document Number"
      {...register("businessDocumentNumber")}
     />
    </FormControl>
   </Grid>
  ),
 },
 {
  label: "Banking Details",
  content: (
   <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
    <FormControl>
     <FormLabel>Account Name</FormLabel>
     <Input placeholder="Account Name" {...register("accountName")} />
    </FormControl>
    <FormControl>
     <FormLabel>Account Number</FormLabel>

     <Input placeholder="Account Number" {...register("accountNumber")} />
    </FormControl>
    <FormControl>
     <FormLabel>IFSC Code</FormLabel>

     <Input placeholder="IFSC Code" {...register("ifscCode")} />
    </FormControl>
    <FormControl>
     <FormLabel>Account Type</FormLabel>
     <Select {...register("accountType")} placeholder="Select account type">
      <option value="saving">Saving</option>
      <option value="current">Current</option>
     </Select>
    </FormControl>
    <FormControl>
     <FormLabel>Branch Name</FormLabel>

     <Input placeholder="Branch Name" {...register("branchName")} />
    </FormControl>
    <FormControl>
     <FormLabel>Bank Name</FormLabel>

     <Input placeholder="Bank Name" {...register("bankName")} />
    </FormControl>
   </Grid>
  ),
 },
 {
  label: "Franchise Details",
  content: (
   <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
    <FormControl>
     <FormLabel>Franchise Type</FormLabel>
     <Select {...register("franchiseType")} placeholder="Select franchise type">
      <option value="car-detailing">Car Detailing</option>
      <option value="car-service">Car Service</option>
      <option value="complete">Complete Detailing + Service</option>
     </Select>
    </FormControl>
    <FormControl>
     <FormLabel>Franchise Applicant Name</FormLabel>

     <Input
      placeholder="Franchise Applicant Name"
      {...register("franchiseApplicantName")}
     />
    </FormControl>
    <FormControl>
     <FormLabel>Franchise Pincode</FormLabel>

     <Input placeholder="Franchise Pincode" {...register("franchisePincode")} />
    </FormControl>
    <FormControl>
     <FormLabel>Franchise Area Name</FormLabel>

     <Input
      placeholder="Franchise Area Name"
      {...register("franchiseAreaName")}
     />
    </FormControl>
    <FormControl>
     <FormLabel>Franchise City</FormLabel>

     <Input placeholder="Franchise City" {...register("franchiseCity")} />
    </FormControl>
    <FormControl>
     <FormLabel>Franchise Radius</FormLabel>
     <Select
      {...register("franchiseRadius")}
      placeholder="Select franchise radius"
     >
      <option value="0-15">0 to 15 KM</option>
      <option value="0-20">0 to 20 KM</option>
      <option value="0-30">0 to 30 KM</option>
     </Select>
    </FormControl>
    <FormControl isRequired>
     <FormLabel>Investment Amount</FormLabel>
     <Input
      placeholder="Investment Amount"
      {...register("investmentAmount", {
       required: "Investment amount is required",
       min: {
        value: 125000,
        message: "The amount is not matching our criteria (minimum ₹1,25,000)",
       },
       onChange: (e) => {
        const value = parseFloat(e.target.value);
        setInvestmentAmount(value);
        if (value >= 125000) {
         setValue("pendingAmount", value - registrationAmount);
        } else {
         setValue("pendingAmount", ""); // Clear pending amount if criteria not met
        }
       },
      })}
     />
     <FormErrorMessage>
      {errors.investmentAmount && errors.investmentAmount.message}
     </FormErrorMessage>
    </FormControl>
    <FormControl isRequired>
     <FormLabel>Registration Amount</FormLabel>
     <Input
      placeholder="Registration Amount"
      defaultValue={`₹${registrationAmount}`}
      isReadOnly
     />
    </FormControl>
    <FormControl isRequired isInvalid={errors.pendingAmount}>
     <FormLabel>Pending Amount</FormLabel>
     <Input
      placeholder="Pending Amount"
      {...register("pendingAmount", { required: "Pending amount is required" })}
      isReadOnly
     />
     <FormErrorMessage>
      {errors.pendingAmount && errors.pendingAmount.message}
     </FormErrorMessage>
    </FormControl>
   </Grid>
  ),
 },
 //  {
 //   label: "Franchise Details",
 //   content: (
 //    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
 //     <FormControl isRequired>
 //      <FormLabel>Investment Amount</FormLabel>
 //      <Input
 //       placeholder="Investment Amount"
 //       {...register("investmentAmount", {
 //        required: "Investment amount is required",
 //        min: {
 //         value: 125000,
 //         message: "The amount is not matching our criteria (minimum ₹1,25,000)",
 //        },
 //        onChange: (e) => {
 //         const value = parseFloat(e.target.value);
 //         setInvestmentAmount(value);
 //         if (value >= 125000) {
 //          setValue("pendingAmount", value - registrationAmount);
 //         } else {
 //          setValue("pendingAmount", ""); // Clear pending amount if criteria not met
 //         }
 //        },
 //       })}
 //      />
 //      <FormErrorMessage>
 //       {errors.investmentAmount && errors.investmentAmount.message}
 //      </FormErrorMessage>
 //     </FormControl>

 //  <FormControl isRequired>
 //   <FormLabel>Registration Amount</FormLabel>
 //   <Input
 //    placeholder="Registration Amount"
 //    defaultValue={`₹${registrationAmount}`}
 //    isReadOnly
 //   />
 //  </FormControl>

 //  <FormControl isRequired isInvalid={errors.pendingAmount}>
 //   <FormLabel>Pending Amount</FormLabel>
 //   <Input
 //    placeholder="Pending Amount"
 //    {...register("pendingAmount", { required: "Pending amount is required" })}
 //    isReadOnly
 //   />
 //   <FormErrorMessage>
 //    {errors.pendingAmount && errors.pendingAmount.message}
 //   </FormErrorMessage>
 //  </FormControl>
 //    </Grid>
 //   ),
 //  },

 {
  label: "Create User & Document Upload",
  content: (
   <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
    <FormControl>
     <FormLabel>Username</FormLabel>

     <Input placeholder="Username" {...register("username")} />
    </FormControl>
    <FormControl>
     <FormLabel>Email ID</FormLabel>

     <Input placeholder="Email ID" {...register("userEmail")} />
    </FormControl>
    <FormControl>
     <FormLabel>Password</FormLabel>

     <Input placeholder="Password" type="password" {...register("password")} />
    </FormControl>
    <FormControl>
     <FormLabel>Current Work Profession</FormLabel>

     <Input
      placeholder="Current Work Profession"
      {...register("currentProfession")}
     />
    </FormControl>
    <FormControl>
     <FormLabel>Why do you want a franchise?</FormLabel>

     <Textarea
      placeholder="Why do you want a franchise?"
      rows={4}
      {...register("franchiseReason")}
     />
    </FormControl>
    <Box>
     <Text mb={2}>Upload Documents:</Text>
     <Button as="label" variant="solid" colorScheme="blue" mb={2}>
      Upload Pan Card
      <input type="file" hidden {...register("panUpload")} />
     </Button>
     <Button as="label" variant="solid" colorScheme="blue" mb={2}>
      Upload Aadhar / Voter ID / DL
      <input type="file" hidden {...register("aadharUpload")} />
     </Button>
     <Button as="label" variant="solid" colorScheme="blue" mb={2}>
      Upload Business Docs
      <input type="file" hidden {...register("businessDocUpload")} />
     </Button>
     <Button as="label" variant="solid" colorScheme="blue" mb={2}>
      Upload Bank Account Proof
      <input type="file" hidden {...register("bankProofUpload")} />
     </Button>
     <Button as="label" variant="solid" colorScheme="blue" mb={2}>
      Upload Photo
      <input type="file" hidden {...register("photoUpload")} />
     </Button>
    </Box>
   </Grid>
  ),
 },
 {
  label: "Payment",
  content: (
   <Box>
    <Text>Registration Amount: ₹{registrationAmount}</Text>
    <Text>Investment Amount: ₹{investmentAmount}</Text>
    <Text>Pending Amount: ₹{pendingAmount}</Text>
   </Box>
  ),
 },
];

export default function HorizontalStepper() {
 const { isOpen, onOpen, onClose } = useDisclosure();

 const dispatch = useDispatch();
 const [activeStep, setActiveStep] = useState(0);
 const [investmentAmount, setInvestmentAmount] = useState(0);
 const [pendingAmount, setPendingAmount] = useState(0);
 const [completedSteps, setCompletedSteps] = useState(
  Array(steps.length).fill(false)
 );
 const toast = useToast();
 const {
  register,
  handleSubmit,
  formState: { errors },
  trigger,
  setValue,
 } = useForm();

 const handleStepClick = (step) => {
  if (step < activeStep || completedSteps[activeStep]) {
   setActiveStep(step);
  } else {
   trigger().then((isValid) => {
    if (isValid) {
     const updatedSteps = [...completedSteps];
     updatedSteps[activeStep] = true;
     setCompletedSteps(updatedSteps);
     setActiveStep(step);
    } else {
     toast({
      title: "Complete the current step",
      description: "Please fill out all required fields.",
      status: "error",
      duration: 3000,
      isClosable: true,
     });
    }
   });
  }
 };

 //  const handleNext = () => {
 //   trigger().then((isValid) => {
 //    if (isValid) {
 //     const updatedSteps = [...completedSteps];
 //     updatedSteps[activeStep] = true;
 //     setCompletedSteps(updatedSteps);
 //     setActiveStep((prev) => prev + 1);
 //    } else {
 //     toast({
 //      title: "Complete the current step",
 //      description: "Please fill out all required fields.",
 //      status: "error",
 //      duration: 3000,
 //      isClosable: true,
 //     });
 //    }
 //   });
 //  };
 const handleNext = async () => {
  const currentStepFields = stepFields[activeStep];
  const isValid = await trigger(currentStepFields);

  if (isValid) {
   const updatedSteps = [...completedSteps];
   updatedSteps[activeStep] = true;
   setCompletedSteps(updatedSteps);
   setActiveStep((prev) => prev + 1);
   // Calculate pending amount when moving to the payment step
   if (activeStep === steps.length - 2) {
    setPendingAmount(investmentAmount - registrationAmount);
   }
  } else {
   toast({
    title: "Complete the current step",
    description: "Please fill out all required fields.",
    status: "error",
    duration: 3000,
    isClosable: true,
   });
  }
 };
 const handleBack = () => setActiveStep((prev) => prev - 1);

 const handleReset = () => {
  setActiveStep(0);
  setCompletedSteps(Array(steps.length).fill(false));
  setInvestmentAmount(0);
  setPendingAmount(0);
  toast({
   title: "Stepper reset.",
   description: "You've reset the form.",
   status: "info",
   duration: 3000,
   isClosable: true,
  });
 };
 const onSubmit = (data) => {
  console.log("data:", data);
  const formData = new FormData();
  Object.keys(data).forEach((key) => {
   if (data[key] instanceof FileList) {
    Array.from(data[key]).forEach((file) => formData.append(key, file));
   } else {
    formData.append(key, data[key]);
   }
  });
  console.log("formData:", formData);
  dispatch(createFormEntry(formData))
   .unwrap()
   .then(() => {
    toast({
     title: "Form Submitted.",
     description: "You've successfully submitted the form.",
     status: "success",
     duration: 3000,
     isClosable: true,
    });
    setCompletedSteps(Array(steps.length).fill(false));
    // setActiveStep(0);
   })
   .catch((error) => {
    toast({
     title: "Submission Failed.",
     description: error.message || "An error occurred.",
     status: "error",
     duration: 3000,
     isClosable: true,
    });
   });
 };

 const franchiseData = {
  _id: "671801c09d053a9c93ba9186",
  firstName: "Pushpendra",
  lastName: "Singh",
  dob: "2024-10-18T00:00:00.000Z",
  gender: "male",
  contactNumber: "09999504703",
  alternativeContactNumber: "",
  email: "erpushpendrasingh8900@gmail.com",
  address: "Pipra Uttar Patti",
  additionalAddress: "taria",
  panNumber: "12345678910",
  documentType: "dl",
  documentNumber: "bfty547457",
  companyName: "Pushpendra",
  companyType: "limited",
  companyAddress: "2024-10-185",
  businessContactNumber: "+800 2893355",
  businessDocumentType: "coi",
  businessDocumentNumber: "577457757575745",
  accountName: "Pushpendra5475575",
  accountNumber: "1234567899874561",
  ifscCode: "2024-10-1854577",
  accountType: "current",
  branchName: "75454",
  bankName: "57745775755747575745",
  franchiseType: "car-service",
  franchiseApplicantName: "1234567899874561575445",
  franchisePincode: "2024-10-185457754554",
  franchiseAreaName: "57454554754",
  franchiseCity: "75454",
  franchiseRadius: "0-15",
  investmentAmount: "75454754",
  registrationAmount: "₹15,000",
  pendingAmount: "54",
  qualityParameter: "Above 90%",
  username: "dell.8900@outlook.com",
  userEmail: "12345678998454574561575445",
  password: "2024-10-18545775455445745",
  currentProfession: "574545547545475454",
  franchiseReason: "45545754",
  panUpload: [
   {
    fileName: "lss_logo.89bae2c852b508839c31.png",
    filePath:
     "https://car-expert-admin.s3.amazonaws.com/1729626559405-lss_logo.89bae2c852b508839c31.png",
    mimeType: "image/png",
    _id: "671801c09d053a9c93ba9187",
   },
  ],
  aadharUpload: [
   {
    fileName: "lss_logo.89bae2c852b508839c31.png",
    filePath:
     "https://car-expert-admin.s3.amazonaws.com/1729626559650-lss_logo.89bae2c852b508839c31.png",
    mimeType: "image/png",
    _id: "671801c09d053a9c93ba9188",
   },
  ],
  businessDocUpload: [
   {
    fileName: "lss_logo.89bae2c852b508839c31.png",
    filePath:
     "https://car-expert-admin.s3.amazonaws.com/1729626559734-lss_logo.89bae2c852b508839c31.png",
    mimeType: "image/png",
    _id: "671801c09d053a9c93ba9189",
   },
  ],
  bankProofUpload: [
   {
    fileName: "lss_logo.89bae2c852b508839c31.png",
    filePath:
     "https://car-expert-admin.s3.amazonaws.com/1729626559818-lss_logo.89bae2c852b508839c31.png",
    mimeType: "image/png",
    _id: "671801c09d053a9c93ba918a",
   },
  ],
  photoUpload: [
   {
    fileName: "lss_logo.89bae2c852b508839c31.png",
    filePath:
     "https://car-expert-admin.s3.amazonaws.com/1729626559897-lss_logo.89bae2c852b508839c31.png",
    mimeType: "image/png",
    _id: "671801c09d053a9c93ba918b",
   },
  ],
  __v: 0,
 };

 return (
  <Box width="90%" m="auto" py={10}>
   <Heading as="h1" size="lg" textAlign="center" mb={6}>
    Franchise Form
   </Heading>
   <Text textAlign="center" mb={4}>
    Create the contract terms with this guided process
   </Text>

   <HStack spacing={8}>
    {steps.map((label, index) => (
     <Box
      key={index}
      w="full"
      h="80px"
      p={4}
      bg={index === activeStep ? "blue.500" : "gray.100"}
      color={index === activeStep ? "white" : "black"}
      borderRadius="md"
      textAlign="center"
      fontWeight="bold"
      cursor="pointer"
      onClick={() => handleStepClick(index)}
     >
      {label}
     </Box>
    ))}
   </HStack>

   <Box mt={5} p={4} bg="gray.50" borderRadius="md" height="70vh">
    <form onSubmit={handleSubmit(onSubmit)}>
     {
      stepContent(
       register,
       errors,
       setValue,
       investmentAmount,
       setInvestmentAmount,
       pendingAmount
      )[activeStep]?.content
     }
     <Stack direction="row" mt={6} justify="space-between">
      <Button
       variant="outline"
       //  isDisabled={activeStep === 0}
       onClick={handleBack}
      >
       Back
      </Button>

      {/* Conditionally show Next/Finish or Review button */}
      <Box
       display="flex"
       alignItems="center"
       justifyContent="space-between"
       p={4}
      >
       {activeStep < steps.length - 1 ? (
        <Button colorScheme="blue" type="button" onClick={handleNext}>
         Next
        </Button>
       ) : (
        <>
         <Button colorScheme="blue" type="button" onClick={onOpen}>
          Review
         </Button>
         <Button
          variant="solid"
          colorScheme="green"
          ml={4}
          onClick={handleSubmit(onSubmit)} // Correctly handle form submission with data
         >
          Pay Now
         </Button>
        </>
       )}
      </Box>
     </Stack>
    </form>

    <DialogTemplate
     isOpen={isOpen}
     onClose={onClose}
     title="User Details"
     size="lg"
    >
     <FranchiseDetails data={franchiseData} />
    </DialogTemplate>
   </Box>

   {activeStep === steps.length && (
    <Box mt={4} textAlign="center">
     <Text mb={4}>All steps completed - you're finished!</Text>
     <Button onClick={handleReset} colorScheme="blue">
      Reset
     </Button>
    </Box>
   )}

   <Divider my={10} />
  </Box>
 );
}
