import React, { useState } from "react";
import {
 Menu,
 MenuButton,
 MenuList,
 MenuItem,
 IconButton,
 Avatar,
 Flex,
 Button,
} from "@chakra-ui/react";
import { FiUser, FiLogOut, FiSettings, FiShoppingCart } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../redux/authSlice";
// import { logoutUser } from "../../redux/authSlice";

export const UserAvatar = () => {
 const { user, isAuth } = useSelector((s) => s.auth);
 //  const [isAuth, setIsLoggedIn] = useState(() => {
 //   return user ? true : false;
 //  });

 const dispatch = useDispatch();

 const handleLogout = () => {
  dispatch(logoutUser())
   .unwrap()
   .then(() => {
    console.log("Successfully logged out");
   })
   .catch((error) => {
    console.error("Logout failed:", error);
   });
 };
 console.log("isAuth:", isAuth);
 return (
  <Menu>
   <MenuButton
    as={IconButton}
    icon={<FiUser />}
    variant="outline"
    aria-label="User Menu"
    bgColor={isAuth ? "green.100" : "gray.100"}
    _hover={{
     bgColor: isAuth ? "green.200" : "gray.200",
    }}
    size="lg"
    border="2px solid"
    borderColor={isAuth ? "green.500" : "red.500"}
   >
    <Avatar
     size="sm"
     name={user?.mobile || "Guest"}
     src="/path/to/avatar.png"
    />
   </MenuButton>
   <MenuList bgColor={"#000D6B"}>
    {/* User Info */}
    {isAuth && (
     <Flex
      bgColor={"#000D6B"}
      direction="column"
      align="center"
      p={4}
      borderBottom="1px solid #E2E8F0"
     >
      <Avatar color={"blackAlpha.300"} size="lg" name={user?.name || "User"} />
      <strong>{user?.name || "Guest"}</strong>
      <small>{user?.mobile || ""}</small>
     </Flex>
    )}

    {/* Menu Options */}
    <MenuItem
     bgColor={"#000D6B"}
     icon={<FiShoppingCart />}
     onClick={() => console.log("Orders clicked")}
    >
     Orders
    </MenuItem>
    <MenuItem
     bgColor={"#000D6B"}
     icon={<FiSettings />}
     onClick={() => console.log("Profile clicked")}
    >
     Profile
    </MenuItem>
    {isAuth && (
     <MenuItem
      bgColor={"#000D6B"}
      icon={<FiLogOut />}
      color="red.500"
      onClick={handleLogout || (() => console.log("Logged out"))}
     >
      Logout
     </MenuItem>
    )}
    {!isAuth && (
     <MenuItem
      bgColor={"#000D6B"}
      icon={<FiLogOut />}
      color="green.500"
      // onClick={handleLogout || (() => console.log("Logged out"))}
     >
      Login
     </MenuItem>
    )}
   </MenuList>
  </Menu>
 );
};

export default UserAvatar;

// import React from "react";
// import {
//  Box,
//  HStack,
//  Badge,
//  Tooltip,
//  Flex,
//  IconButton,
//  Button,
//  Avatar,
//  Menu,
//  MenuButton,
//  MenuList,
//  MenuItem,
//  Drawer,
//  DrawerBody,
//  DrawerOverlay,
//  DrawerContent,
//  DrawerCloseButton,
//  useDisclosure,
//  Text,
//  Image,
//  Modal,
//  ModalOverlay,
//  ModalContent,
//  ModalHeader,
//  Heading,
//  ModalBody,
//  ModalFooter,
// } from "@chakra-ui/react";
// import FindCarForm from "../UI/FindCarForm";

// export const UserAvatar = ({ isAuth, userName, avatarUrl, user }) => {
//  const { isOpen, onOpen, onClose } = useDisclosure();
//  console.log("user:", user);
//  return (
//   <HStack spacing={4} align="center" onClick={onOpen}>
//    <Box position="relative">
//     {/* User Avatar */}
//     <Avatar
//      size="md"
//      name={userName || "Guest"}
//      src={avatarUrl || "http://localhost:3000/logo1.png"}
//      bg={isAuth ? "green.300" : "gray.300"}
//      border="2px solid"
//      borderColor={isAuth ? "green.500" : "red.500"}
//     />

//     {/* Status Badge */}
//     <Badge
//      position="absolute"
//      bottom="2"
//      left="15"
//      bg={isAuth ? "green.400" : "#000D6B"}
//      color="white"
//      fontSize="0.8rem"
//      px={2}
//      py={1}
//      borderRadius="full"
//     >
//      {isAuth ? "Online" : `${user.mobile}`}
//     </Badge>
//    </Box>

//    {/* User Info */}
//    {/* <Tooltip
//     label={isAuth ? "Welcome back!" : "Please log in"}
//     aria-label="User status tooltip"
//    >
//     <Text
//      fontSize="md"
//      fontWeight="bold"
//      m="0px 2px"
//      color={isAuth ? "green.500" : "red.500"}
//     >
//      {isAuth ? userName : "Guest"}
//     </Text>
//    </Tooltip> */}
//    <Modal onClose={onClose} size={"xl"} isOpen={isOpen}>
//     <ModalOverlay />
//     <ModalContent>
//      <ModalBody>
//       <Flex direction={"column"} gap={2}>
//        <FindCarForm />
//       </Flex>
//      </ModalBody>
//      <ModalFooter>
//       <Button bgColor="#000D6B" color="#fff" onClick={onClose}>
//        Close
//       </Button>
//      </ModalFooter>
//     </ModalContent>
//    </Modal>
//   </HStack>
//  );
// };
