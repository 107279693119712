// src/redux/store.js
import { configureStore } from "@reduxjs/toolkit";
import servicesReducer from "./servicesSlice";
import brandsReducer from "./brandsSlice";
import modelsReducer from "./modelsSlice";
import trendingReducer from "./tranding";
import FranchiseformSlice from "./FranchiseformSlice";
import authSlice from "./authSlice";
import carsSlice from "./carsSlice";
import userSlice from "./userSlice";
import cartSlice from "./cartSlice";
import reviewsSlice from "./reviewsSlice";
import companySlice from "./companySlice";
import faqSlice from "./faqSlice";

const store = configureStore({
 reducer: {
  auth: authSlice,
  services: servicesReducer,
  brands: brandsReducer,
  models: modelsReducer,
  models: modelsReducer,
  trending: trendingReducer,
  Franchise: FranchiseformSlice,
  user_cars: carsSlice,
  userProfile: userSlice,
  cart: cartSlice,
  reviews: reviewsSlice,
  company: companySlice,
  faqs: faqSlice,
 },
});

export default store;
