import React, { useEffect, useState } from "react";
import {
 Box,
 Image,
 Text,
 Button,
 VStack,
 HStack,
 Divider,
 Heading,
 Flex,
 Radio,
 RadioGroup,
 Stack,
} from "@chakra-ui/react";
import { useDispatch, useSelector } from "react-redux";
import { applyCoupon, fetchCouponCodes } from "../../redux/cartSlice";
import { useNavigate } from "react-router-dom";

const CartCard = ({
 cartItems = [],
 handleQuantityChange,
 handleRemove,
 coupon = [],
}) => {
 const dispatch = useDispatch();
 const navigate = useNavigate();
 const { cart } = useSelector((state) => state.cart);
 const { user } = useSelector((state) => state.auth);

 const [visibleItems, setVisibleItems] = useState(2);
 const [selectedCoupon, setSelectedCoupon] = useState(cart?.couponCode || "");

 const items = Array.isArray(cartItems) ? cartItems : [];
 const totalAmount = items.reduce(
  (acc, item) => acc + item.price * (item.quantity || 1),
  0
 );

 useEffect(() => {
  if (coupon.length === 0) {
   dispatch(fetchCouponCodes());
  }
  if (cart?.couponCode) {
   setSelectedCoupon(cart.couponCode);
  }
 }, [dispatch, coupon.length, cart?.couponCode]);

 const isCouponApplicable = (coupon) => {
  if (!coupon || !coupon.subCategory || !coupon.subCategory._id) return false;

  const isSubCategoryMatch = items.some(
   (item) => item.subCategoryId === coupon.subCategory._id
  );
  const isNotExpired = new Date(coupon.expiryDate) > new Date();
  return isSubCategoryMatch && isNotExpired;
 };

 const handleApplyCoupon = (couponCode) => {
  setSelectedCoupon(couponCode);
  const userId = user?._id;
  dispatch(applyCoupon({ userId, couponCode }));
 };

 const handleToggle = () => {
  setVisibleItems((prev) => (prev < items.length ? items.length : 2));
 };

 return (
  <Box
   bg="white"
   p={6}
   borderWidth={1}
   borderRadius="lg"
   shadow="lg"
   maxW="lg"
   mx="auto"
  >
   <Box mb={6}>
    <Heading as="h3" size="md" textAlign="center">
     Your Cart
    </Heading>
   </Box>

   {/* Cart Items */}
   <VStack spacing={4} align="stretch" mb={6}>
    {items.length === 0 ? (
     <Text textAlign="center" fontSize="lg" color="gray.500">
      No items in cart
     </Text>
    ) : (
     items.slice(0, visibleItems).map((item) => (
      <VStack
       key={item._id}
       p={4}
       borderWidth={1}
       borderRadius="md"
       shadow="sm"
       bg="gray.50"
      >
       <HStack spacing={4} w="100%">
        <VStack align="start" spacing={2} flex="2">
         <Text fontWeight="bold" fontSize="lg">
          Product: {item?.productDetails?.productName}
         </Text>
         <Text color="gray.600">Price: ₹{item.price}</Text>

         {/* Quantity controls */}
         {item?.type !== "Product" && (
          <HStack spacing={3} mt={2}>
           <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
            -
           </Button>
           <Text fontWeight="bold">{item.quantity}</Text>
           <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
            +
           </Button>
          </HStack>
         )}

         <Button
          variant="link"
          colorScheme="red"
          size="sm"
          onClick={() => handleRemove(item)}
         >
          Remove
         </Button>
        </VStack>
        <Image
         boxSize="100px"
         src={
          item?.type === "Product"
           ? item?.productDetails?.productImage
           : item?.productDetails?.image
         }
        />
       </HStack>
      </VStack>
     ))
    )}
    {items.length > 2 && (
     <Button
      colorScheme="blue"
      size="sm"
      variant="outline"
      onClick={handleToggle}
     >
      {visibleItems < items.length ? "Show More" : "Show Less"}
     </Button>
    )}
   </VStack>

   {/* Coupon Section */}
   <Box>
    <Divider mb={4} />
    <VStack align="stretch" spacing={2}>
     <Text fontWeight="bold">Apply Coupons</Text>
     <Flex justify="space-between" flexWrap="wrap">
      {coupon?.map((item) => {
       if (!item || !item.subCategory || !item.subCategory._id) return null;

       return isCouponApplicable(item) ? (
        <Box
         key={item._id}
         p={2}
         borderWidth={1}
         borderRadius="md"
         bgColor="blue.700"
         color="white"
         w="100%"
        >
         <RadioGroup onChange={setSelectedCoupon} value={selectedCoupon}>
          <Stack direction="row" align="center" justify="space-between">
           <Radio value={item.code}>{item.title}</Radio>
           {selectedCoupon === item.code && (
            <Button
             colorScheme="blue"
             onClick={() => handleApplyCoupon(item.code)}
            >
             Apply
            </Button>
           )}
          </Stack>
         </RadioGroup>
         <Text>Code: {item.code}</Text>
         <Text mt={-2}>Discount: {item.discountPercent}%</Text>
        </Box>
       ) : null;
      })}
     </Flex>

     {/* Cart Summary */}
     <Flex justify="space-between">
      <Text fontWeight="bold">Subtotal:</Text>
      <Text>₹{totalAmount}</Text>
     </Flex>
     <Flex justify="space-between">
      <Text fontWeight="bold">Discount:</Text>
      <Text>- ₹{cart?.couponDiscount || 0}</Text>
     </Flex>
     <Flex justify="space-between" fontWeight="bold" fontSize="lg">
      <Text>Total:</Text>
      <Text>₹{cart?.finalPrice?.toFixed(2)}</Text>
     </Flex>

     {/* Place Order Button */}
     <Button
      colorScheme="red"
      w="100%"
      mt={4}
      size="lg"
      onClick={() => navigate("/slot-booking")}
     >
      Place Order
     </Button>
    </VStack>
   </Box>
  </Box>
 );
};

export default CartCard;

// import React, { useEffect, useState } from "react";
// import {
//  Box,
//  Image,
//  Text,
//  Button,
//  VStack,
//  HStack,
//  Divider,
//  Heading,
//  Flex,
//  Radio,
//  RadioGroup,
//  Stack,
// } from "@chakra-ui/react";
// import { useDispatch, useSelector } from "react-redux";
// import { applyCoupon, fetchCouponCodes } from "../../redux/cartSlice";
// import { useNavigate } from "react-router-dom";

// const CartCard = ({
//  cartItems = [],
//  handleQuantityChange,
//  handleRemove,
//  coupon = [],
// }) => {
//  const dispatch = useDispatch();
//  const navigate = useNavigate();
//  const { isAuth, user } = useSelector((state) => state.auth);
//  const { cart } = useSelector((state) => state.cart);

//  const [visibleItems, setVisibleItems] = useState(2);
//  const [selectedCoupon, setSelectedCoupon] = useState(cart?.couponCode || ""); // Initialize with applied coupon code

//  const items = Array.isArray(cartItems) ? cartItems : [];

//  const totalAmount = items.reduce(
//   (acc, item) => acc + item.price * (item.quantity || 1),
//   0
//  );

//  useEffect(() => {
//   if (coupon.length === 0) {
//    dispatch(fetchCouponCodes());
//   }
//   // Keep the selected coupon synced with Redux store
//   if (cart?.couponCode) {
//    setSelectedCoupon(cart.couponCode);
//   }
//  }, [dispatch, coupon.length, cart?.couponCode]);

//  const isCouponApplicable = (coupon) => {
//   if (!coupon || !coupon.subCategory || !coupon.subCategory._id) {
//    return false;
//   }
//   const isSubCategoryMatch = items.some(
//    (item) => item.subCategoryId === coupon.subCategory._id
//   );
//   const isNotExpired = new Date(coupon.expiryDate) > new Date();
//   return isSubCategoryMatch && isNotExpired;
//  };

//  const handleApplyCoupon = (couponCode) => {
//   setSelectedCoupon(couponCode);
//   const userId = user?._id;
//   dispatch(applyCoupon({ userId, couponCode }));
//  };

//  const handleToggle = () => {
//   setVisibleItems((prev) => (prev < items.length ? items.length : 2));
//  };

//  return (
//   <Box
//    bg="white"
//    p={6}
//    borderWidth={1}
//    borderRadius="lg"
//    shadow="lg"
//    maxW="lg"
//    mx="auto"
//   >
//    <Box mb={6}>
//     <Heading as="h3" size="md" textAlign="center">
//      Your Cart
//     </Heading>
//    </Box>

//    <VStack spacing={4} align="stretch" mb={6}>
//     {items.length === 0 ? (
//      <Text textAlign="center" fontSize="lg" color="gray.500">
//       No items in cart
//      </Text>
//     ) : (
//      items.slice(0, visibleItems).map((item) => (
//       <VStack
//        key={item._id}
//        p={4}
//        borderWidth={1}
//        borderRadius="md"
//        shadow="sm"
//        bg="gray.50"
//       >
//        <HStack spacing={4} w={"100%"}>
//         <VStack align="start" spacing={2} flex="2">
//          <Text fontWeight="bold" fontSize="lg">
//           Product : {item?.productDetails?.productName}
//          </Text>
//          <Text color="gray.600">Price: ₹{item.price}</Text>
//          {item?.type !== "Product" && (
//           <HStack spacing={3} mt={2}>
//            <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
//             -
//            </Button>
//            <Text fontWeight="bold">{item.quantity}</Text>
//            <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
//             +
//            </Button>
//           </HStack>
//          )}
//          <Button
//           variant="link"
//           colorScheme="red"
//           size="sm"
//           onClick={() => handleRemove(item)}
//          >
//           Remove
//          </Button>
//         </VStack>
//         <Image
//          boxSize={"100px"}
//          src={
//           item?.type === "Product"
//            ? item?.productDetails?.productImage
//            : item?.productDetails?.image
//          }
//         />
//        </HStack>
//       </VStack>
//      ))
//     )}
//     {items.length > 2 && (
//      <Button
//       colorScheme="blue"
//       size="sm"
//       variant="outline"
//       onClick={handleToggle}
//      >
//       {visibleItems < items.length ? "Show More" : "Show Less"}
//      </Button>
//     )}
//    </VStack>

//    <Box>
//     <Divider mb={4} />
//     <VStack align="stretch" spacing={2}>
//      <Text fontWeight="bold">Apply Coupons</Text>
//      <Flex justify="space-between">
//       {coupon?.map((item) => {
//        if (!item || !item.subCategory || !item.subCategory._id) {
//         return null;
//        }

//        return isCouponApplicable(item) ? (
//         <Box
//          bgColor={"#000D6B"}
//          border={"1px solid red"}
//          w="100%"
//          key={item._id}
//          p={2}
//          borderWidth={1}
//          borderRadius="md"
//          color={"#FFFF"}
//         >
//          <RadioGroup onChange={setSelectedCoupon} value={selectedCoupon}>
//           <Stack direction="row" align={"center"} justify={"space-between"}>
//            <Radio value={item.code}>{item.title}</Radio>
//            {selectedCoupon === item.code && (
//             <Button
//              colorScheme="blue"
//              onClick={() => handleApplyCoupon(item.code)}
//             >
//              Apply Coupon
//             </Button>
//            )}
//           </Stack>
//          </RadioGroup>
//          <Text>Code: {item.code}</Text>
//          <Text mt={-5}>Discount: {item.discountPercent}%</Text>
//         </Box>
//        ) : null;
//       })}
//      </Flex>

//      <Flex justify="space-between">
//       <Text fontWeight="bold">Subtotal:</Text>
//       <Text>₹{totalAmount}</Text>
//      </Flex>
//      <Flex justify="space-between">
//       <Text fontWeight="bold">Discount:</Text>
//       <Text>- ₹{cart?.couponDiscount || 0}</Text>
//      </Flex>
//      <Flex justify="space-between" fontWeight="bold" fontSize="lg">
//       <Text>Total:</Text>
//       <Text>₹{cart?.finalPrice?.toFixed(2)}</Text>
//      </Flex>
//      <Button
//       colorScheme="red"
//       w="100%"
//       mt={4}
//       size="lg"
//       onClick={() => navigate("/slot-booking")}
//      >
//       Place Order
//      </Button>
//     </VStack>
//    </Box>
//   </Box>
//  );
// };

// export default CartCard;

// // import {
// //  Box,
// //  Image,
// //  Text,
// //  Button,
// //  VStack,
// //  HStack,
// //  Divider,
// //  Heading,
// //  Flex,
// //  Radio,
// //  RadioGroup,
// //  Stack,
// // } from "@chakra-ui/react";
// // import React, { useEffect, useState } from "react";
// // import { useDispatch, useSelector } from "react-redux";
// // import { applyCoupon, fetchCouponCodes } from "../../redux/cartSlice";

// // const CartCard = ({
// //  cartItems = [],
// //  handleQuantityChange,
// //  handleRemove,
// //  coupon = [],
// // }) => {
// //  // State to control the number of items displayed
// //  const dispatch = useDispatch();
// //  const { isAuth, user } = useSelector((state) => state.auth);
// //  const [visibleItems, setVisibleItems] = useState(2);
// //  const [selectedCoupon, setSelectedCoupon] = useState(""); // State to track selected coupon

// //  // Ensure `cartItems` is always an array
// //  const items = Array.isArray(cartItems) ? cartItems : [];

// //  // Calculate totals
// //  const totalAmount = items.reduce(
// //   (acc, item) => acc + item.price * (item.quantity || 1),
// //   0
// //  );

// //  const discountPercentage = 10; // Example discount percentage
// //  const discountAmount = (totalAmount * discountPercentage) / 100;
// //  const finalAmount = totalAmount - discountAmount;

// //  // Function to toggle items display
// //  const handleToggle = () => {
// //   if (visibleItems < items.length) {
// //    setVisibleItems(items.length); // Show all items
// //   } else {
// //    setVisibleItems(2); // Show only the first 2 items
// //   }
// //  };

// //  useEffect(() => {
// //   if (cartItems && coupon.length === 0) {
// //    dispatch(fetchCouponCodes());
// //   }
// //  }, [dispatch, coupon.length]);

// //  // Function to check if any cart item matches the coupon's subcategory
// //  const isCouponApplicable = (coupon) => {
// //   if (!coupon || !coupon.subCategory || !coupon.subCategory._id) {
// //    return false; // Return false if any required property is missing
// //   }
// //   const isSubCategoryMatch = items.some(
// //    (item) => item.subCategoryId === coupon.subCategory._id
// //   );
// //   const isNotExpired = new Date(coupon.expiryDate) > new Date(); // Check if coupon is not expired
// //   return isSubCategoryMatch && isNotExpired;
// //  };

// //  const handleApplyCoupon = (couponCode) => {
// //   // Call API or dispatch action to apply the coupon based on the couponCode
// //   console.log("Applying coupon:", user);
// //   const userId = user?._id;
// //   console.log("userID:", userId);
// //   dispatch(applyCoupon({ userId, couponCode }));
// //   // Example: dispatch(applyCouponAction(couponCode));
// //  };
// //  console.log("items:", cartItems);
// //  const { cart, couponCodes, status, error } = useSelector(
// //   (state) => state.cart
// //  );
// //  console.log("cart:", cart);
// //  return (
// //   <Box
// //    bg="white"
// //    p={6}
// //    borderWidth={1}
// //    borderRadius="lg"
// //    shadow="lg"
// //    maxW="lg"
// //    mx="auto"
// //   >
// //    {/* Header */}
// //    <Box mb={6}>
// //     <Heading as="h3" size="md" textAlign="center">
// //      Your Cart
// //     </Heading>
// //    </Box>

// //    {/* Body */}
// //    <VStack spacing={4} align="stretch" mb={6}>
// //     {items.length === 0 ? (
// //      <Text textAlign="center" fontSize="lg" color="gray.500">
// //       No items in cart
// //      </Text>
// //     ) : (
// //      items.slice(0, visibleItems).map((item) => (
// //       <VStack
// //        key={item._id}
// //        p={4}
// //        borderWidth={1}
// //        borderRadius="md"
// //        shadow="sm"
// //        bg="gray.50"
// //       >
// //        <HStack spacing={4} w={"100%"}>
// //         <VStack align="start" spacing={2} flex="2">
// //          <Text fontWeight="bold" fontSize="lg">
// //           Product : {item?.productDetails?.productName}
// //          </Text>
// //          <Text color="gray.600">Price: ₹{item.price}</Text>
// //          {item?.type !== "Product" && (
// //           <HStack spacing={3} mt={2}>
// //            <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
// //             -
// //            </Button>
// //            <Text fontWeight="bold">{item.quantity}</Text>
// //            <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
// //             +
// //            </Button>
// //           </HStack>
// //          )}
// //          <Button
// //           variant="link"
// //           colorScheme="red"
// //           size="sm"
// //           onClick={() => handleRemove(item)}
// //          >
// //           Remove
// //          </Button>
// //         </VStack>
// //         <Image
// //          boxSize={"100px"}
// //          src={
// //           item?.type === "Product"
// //            ? item?.productDetails?.productImage
// //            : item?.productDetails?.image
// //          }
// //         />
// //        </HStack>
// //       </VStack>
// //      ))
// //     )}
// //     {/* Toggle Button */}
// //     {items.length > 2 && (
// //      <Button
// //       colorScheme="blue"
// //       size="sm"
// //       variant="outline"
// //       onClick={handleToggle}
// //      >
// //       {visibleItems < items.length ? "Show More" : "Show Less"}
// //      </Button>
// //     )}
// //    </VStack>

// //    {/* Footer */}
// //    <Box>
// //     <Divider mb={4} />
// //     <VStack align="stretch" spacing={2}>
// //      <Text fontWeight="bold">Apply Coupons</Text>
// //      {/* <Flex justify="space-between">
// //       {coupon?.map((item) => {
// //        return isCouponApplicable(item.subCategory._id) ? (
// //         <Box
// //          bgColor={"#000D6B"}
// //          border={"1px solid red"}
// //          w="100%"
// //          key={item._id}
// //          p={2}
// //          borderWidth={1}
// //          borderRadius="md"

// //          color={"#FFFF"}
// //         >
// //          <RadioGroup onChange={setSelectedCoupon} value={selectedCoupon}>
// //           <Stack direction="row" align={"center"} justify={"space-between"}>
// //            <Radio value={item.code}>{item.title}</Radio>
// //            {selectedCoupon === item.code && (
// //             <Button
// //              colorScheme="blue"
// //              onClick={() => handleApplyCoupon(item.code)}
// //             >
// //              Apply Coupon
// //             </Button>
// //            )}
// //           </Stack>
// //          </RadioGroup>

// //          <Text>Code: {item.code}</Text>
// //          <Text mt={-5}>Discount: {item.discountPercent}%</Text>

// //         </Box>
// //        ) : null;
// //       })}
// //      </Flex> */}
// //      <Flex justify="space-between">
// //       {coupon?.map((item) => {
// //        // Ensure item and item.subCategory exist before accessing properties
// //        if (!item || !item.subCategory || !item.subCategory._id) {
// //         return null; // Skip rendering if required properties are missing
// //        }

// //        return isCouponApplicable(item) ? (
// //         <Box
// //          bgColor={"#000D6B"}
// //          border={"1px solid red"}
// //          w="100%"
// //          key={item._id}
// //          p={2}
// //          borderWidth={1}
// //          borderRadius="md"
// //          color={"#FFFF"}
// //         >
// //          <RadioGroup onChange={setSelectedCoupon} value={selectedCoupon}>
// //           <Stack direction="row" align={"center"} justify={"space-between"}>
// //            <Radio value={item.code}>{item.title}</Radio>
// //            {selectedCoupon === item.code && (
// //             <Button
// //              colorScheme="blue"
// //              onClick={() => handleApplyCoupon(item.code)}
// //             >
// //              Apply Coupon
// //             </Button>
// //            )}
// //           </Stack>
// //          </RadioGroup>
// //          <Text>Code: {item.code}</Text>
// //          <Text mt={-5}>Discount: {item.discountPercent}%</Text>
// //         </Box>
// //        ) : null;
// //       })}
// //      </Flex>

// //      <Flex justify="space-between">
// //       <Text fontWeight="bold">Subtotal:</Text>
// //       <Text>₹{totalAmount}</Text>
// //      </Flex>
// //      <Flex justify="space-between">
// //       <Text fontWeight="bold">Discount :</Text>
// //       <Text>
// //        - ₹
// //        {
// //         cart?.discount
// //         //  .toFixed(2)
// //        }
// //       </Text>
// //      </Flex>
// //      <Flex justify="space-between" fontWeight="bold" fontSize="lg">
// //       <Text>Total:</Text>
// //       <Text>₹{cart?.finalPrice?.toFixed(2)}</Text>
// //       {/* <Text>₹{finalAmount.toFixed(2)}</Text> */}
// //      </Flex>
// //      <Button
// //       colorScheme="red"
// //       w="100%"
// //       mt={4}
// //       size="lg"
// //       onClick={() => alert("Order placed!")}
// //      >
// //       Place Order
// //      </Button>
// //     </VStack>
// //    </Box>
// //   </Box>
// //  );
// // };

// // export default CartCard;

// // import {
// //  Box,
// //  Image,
// //  Text,
// //  Button,
// //  VStack,
// //  HStack,
// //  Divider,
// //  Heading,
// //  Flex,
// // } from "@chakra-ui/react";
// // import React, { useEffect, useState } from "react";
// // import { useDispatch } from "react-redux";
// // import { fetchCouponCodes } from "../../redux/cartSlice";

// // const CartCard = ({
// //  cartItems = [],
// //  handleQuantityChange,
// //  handleRemove,
// //  coupon = [],
// // }) => {
// //  // State to control the number of items displayed
// //  const [visibleItems, setVisibleItems] = useState(2);
// //  const dispatch = useDispatch();

// //  // Ensure `cartItems` is always an array
// //  const items = Array.isArray(cartItems) ? cartItems : [];

// //  // Calculate totals
// //  const totalAmount = items.reduce(
// //   (acc, item) => acc + item.price * (item.quantity || 1),
// //   0
// //  );

// //  const discountPercentage = 10; // Example discount percentage
// //  const discountAmount = (totalAmount * discountPercentage) / 100;
// //  const finalAmount = totalAmount - discountAmount;

// //  // Function to toggle items display
// //  const handleToggle = () => {
// //   if (visibleItems < items.length) {
// //    setVisibleItems(items.length); // Show all items
// //   } else {
// //    setVisibleItems(2); // Show only the first 2 items
// //   }
// //  };

// //  useEffect(() => {
// //   if (cartItems && coupon.length === 0) {
// //    dispatch(fetchCouponCodes());
// //   }
// //  }, [dispatch, coupon.length]);

// //  // Function to check if any cart item matches the coupon's subcategory
// //  const isCouponApplicable = (couponSubCategoryId) => {
// //   return items.some((item) => item.subCategoryId === couponSubCategoryId);
// //  };

// //  return (
// //   <Box
// //    bg="white"
// //    p={6}
// //    borderWidth={1}
// //    borderRadius="lg"
// //    shadow="lg"
// //    maxW="lg"
// //    mx="auto"
// //   >
// //    {/* Header */}
// //    <Box mb={6}>
// //     <Heading as="h3" size="md" textAlign="center">
// //      Your Cart
// //     </Heading>
// //    </Box>

// //    {/* Body */}
// //    <VStack spacing={4} align="stretch" mb={6}>
// //     {items.length === 0 ? (
// //      <Text textAlign="center" fontSize="lg" color="gray.500">
// //       No items in cart
// //      </Text>
// //     ) : (
// //      items.slice(0, visibleItems).map((item) => (
// //       <VStack
// //        key={item._id}
// //        p={4}
// //        borderWidth={1}
// //        borderRadius="md"
// //        shadow="sm"
// //        bg="gray.50"
// //       >
// //        <HStack spacing={4} w={"100%"}>
// //         <VStack align="start" spacing={2} flex="2">
// //          <Text fontWeight="bold" fontSize="lg">
// //           Product : {item?.productDetails?.productName}
// //          </Text>
// //          <Text color="gray.600">Price: ₹{item.price}</Text>
// //          {item?.type !== "Product" && (
// //           <HStack spacing={3} mt={2}>
// //            <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
// //             -
// //            </Button>
// //            <Text fontWeight="bold">{item.quantity}</Text>
// //            <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
// //             +
// //            </Button>
// //           </HStack>
// //          )}
// //          <Button
// //           variant="link"
// //           colorScheme="red"
// //           size="sm"
// //           onClick={() => handleRemove(item)}
// //          >
// //           Remove
// //          </Button>
// //         </VStack>
// //         <Image
// //          boxSize={"100px"}
// //          src={
// //           item?.type === "Product"
// //            ? item?.productDetails?.productImage
// //            : item?.productDetails?.image
// //          }
// //         />
// //        </HStack>
// //       </VStack>
// //      ))
// //     )}
// //     {/* Toggle Button */}
// //     {items.length > 2 && (
// //      <Button
// //       colorScheme="blue"
// //       size="sm"
// //       variant="outline"
// //       onClick={handleToggle}
// //      >
// //       {visibleItems < items.length ? "Show More" : "Show Less"}
// //      </Button>
// //     )}
// //    </VStack>

// //    {/* Footer */}
// //    <Box>
// //     <Divider mb={4} />
// //     <VStack align="stretch" spacing={2}>
// //      <Text fontWeight="bold">Apply Coupons</Text>
// //      {/* Conditionally render coupon if it matches any cart item's subCategory */}
// //      <Flex justify="space-between">
// //       {coupon?.map((item) => {
// //        return isCouponApplicable(item.subCategory._id) ? (
// //         <Box
// //          key={item._id}
// //          p={4}
// //          borderWidth={1}
// //          borderRadius="md"
// //          bg="green.100"
// //         >
// //          <Text fontWeight="bold">{item.title}</Text>
// //          <Text>Code: {item.code}</Text>
// //          <Text>Discount: {item.discountPercent}%</Text>
// //          <Text>{item.description}</Text>
// //         </Box>
// //        ) : null;
// //       })}
// //      </Flex>
// //      <Flex justify="space-between">
// //       <Text fontWeight="bold">Subtotal:</Text>
// //       <Text>₹{totalAmount}</Text>
// //      </Flex>
// //      <Flex justify="space-between">
// //       <Text fontWeight="bold">Discount ({discountPercentage}%):</Text>
// //       <Text>- ₹{discountAmount.toFixed(2)}</Text>
// //      </Flex>
// //      <Flex justify="space-between" fontWeight="bold" fontSize="lg">
// //       <Text>Total:</Text>
// //       <Text>₹{finalAmount.toFixed(2)}</Text>
// //      </Flex>
// //      <Button
// //       colorScheme="red"
// //       w="100%"
// //       mt={4}
// //       size="lg"
// //       onClick={() => alert("Order placed!")}
// //      >
// //       Place Order
// //      </Button>
// //     </VStack>
// //    </Box>
// //   </Box>
// //  );
// // };

// // export default CartCard;

// // // import {
// // //  Box,
// // //  Image,
// // //  Text,
// // //  Button,
// // //  VStack,
// // //  HStack,
// // //  Divider,
// // //  Heading,
// // //  Flex,
// // // } from "@chakra-ui/react";
// // // import React, { useEffect, useState } from "react";
// // // import { useDispatch } from "react-redux";
// // // import { fetchCouponCodes } from "../../redux/cartSlice";

// // // const CartCard = ({
// // //  cartItems = [],
// // //  handleQuantityChange,
// // //  handleRemove,
// // //  coupon = [],
// // // }) => {
// // //  // State to control the number of items displayed
// // //  const [visibleItems, setVisibleItems] = useState(2);
// // //  const dispatch = useDispatch();
// // //  // Ensure `cartItems` is always an array
// // //  const items = Array.isArray(cartItems) ? cartItems : [];

// // //  // Calculate totals
// // //  const totalAmount = items.reduce(
// // //   (acc, item) => acc + item.price * (item.quantity || 1),
// // //   0
// // //  );

// // //  const discountPercentage = 10; // Example discount percentage
// // //  const discountAmount = (totalAmount * discountPercentage) / 100;
// // //  const finalAmount = totalAmount - discountAmount;

// // //  // Function to toggle items display
// // //  const handleToggle = () => {
// // //   if (visibleItems < items.length) {
// // //    setVisibleItems(items.length); // Show all items
// // //   } else {
// // //    setVisibleItems(2); // Show only the first 2 items
// // //   }
// // //  };
// // //  console.log("coupon:", coupon);
// // //  useEffect(() => {
// // //   if (cartItems && coupon.length === 0) {
// // //    dispatch(fetchCouponCodes());
// // //   }
// // //  }, [dispatch, coupon.length]);
// // //  return (
// // //   <Box
// // //    bg="white"
// // //    p={6}
// // //    borderWidth={1}
// // //    borderRadius="lg"
// // //    shadow="lg"
// // //    maxW="lg"
// // //    mx="auto"
// // //   >
// // //    {/* Header */}
// // //    <Box mb={6}>
// // //     <Heading as="h3" size="md" textAlign="center">
// // //      Your Cart
// // //     </Heading>
// // //    </Box>

// // //    {/* Body */}
// // //    <VStack spacing={4} align="stretch" mb={6}>
// // //     {items.length === 0 ? (
// // //      <Text textAlign="center" fontSize="lg" color="gray.500">
// // //       No items in cart
// // //      </Text>
// // //     ) : (
// // //      items.slice(0, visibleItems).map((item) => (
// // //       <VStack
// // //        key={item._id}
// // //        p={4}
// // //        borderWidth={1}
// // //        borderRadius="md"
// // //        shadow="sm"
// // //        bg="gray.50"
// // //       >
// // //        <HStack spacing={4} w={"100%"}>
// // //         <VStack align="start" spacing={2} flex="2">
// // //          <Text fontWeight="bold" fontSize="lg">
// // //           Product : {item?.productDetails?.productName}
// // //          </Text>
// // //          <Text color="gray.600">Price: ₹{item.price}</Text>
// // //          {item?.type !== "Product" && (
// // //           <HStack spacing={3} mt={2}>
// // //            <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
// // //             -
// // //            </Button>
// // //            <Text fontWeight="bold">{item.quantity}</Text>
// // //            <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
// // //             +
// // //            </Button>
// // //           </HStack>
// // //          )}
// // //          <Button
// // //           variant="link"
// // //           colorScheme="red"
// // //           size="sm"
// // //           onClick={() => handleRemove(item)}
// // //          >
// // //           Remove
// // //          </Button>
// // //         </VStack>
// // //         <Image
// // //          boxSize={"100px"}
// // //          src={
// // //           item?.type === "Product"
// // //            ? item?.productDetails?.productImage
// // //            : item?.productDetails?.image
// // //          }
// // //         />
// // //        </HStack>
// // //       </VStack>
// // //      ))
// // //     )}
// // //     {/* Toggle Button */}
// // //     {items.length > 2 && (
// // //      <Button
// // //       colorScheme="blue"
// // //       size="sm"
// // //       variant="outline"
// // //       onClick={handleToggle}
// // //      >
// // //       {visibleItems < items.length ? "Show More" : "Show Less"}
// // //      </Button>
// // //     )}
// // //    </VStack>

// // //    {/* Footer */}
// // //    <Box>
// // //     <Divider mb={4} />
// // //     <VStack align="stretch" spacing={2}>
// // //      <Text fontWeight="bold">Apply Coupons</Text>
// // //      {/*
// // // {
// // //     "_id": "66c8549745f463615cc7301a",
// // //     "title": "Festive20",
// // //     "code": "Carexpertfes20",
// // //     "discountPercent": 20,
// // //     "description": "Get 20% cashback",
// // //     "subCategory": {
// // //         "_id": "66a72f5c0bd181b4f9e3f2f4",
// // //         "categoryId": "66a0e0d678583b9481b39f99",
// // //         "subCategoryName": "Radiator ",
// // //         "subCategoryImage": "https://car-expert.s3.ap-south-1.amazonaws.com/1722232668512-Thumbnail%20%283%29.jpg",
// // //         "createdAt": "2024-07-29T05:57:48.732Z",
// // //         "updatedAt": "2024-07-29T05:57:48.732Z",
// // //         "__v": 0
// // //     },
// // //     "type": "discount",
// // //     "expiryDate": "2024-09-30T00:00:00.000Z",
// // //     "isActive": true,
// // //     "createdAt": "2024-08-23T09:21:27.791Z",
// // //     "updatedAt": "2024-09-16T19:04:01.406Z",
// // //     "__v": 0
// // // } */}
// // //      <Flex justify="space-between">
// // //       {coupon?.map((item) => {
// // //        return console.log(item);
// // //       })}
// // //      </Flex>
// // //      <Flex justify="space-between">
// // //       <Text fontWeight="bold">Subtotal:</Text>
// // //       <Text>₹{totalAmount}</Text>
// // //      </Flex>
// // //      <Flex justify="space-between">
// // //       <Text fontWeight="bold">Subtotal:</Text>
// // //       <Text>₹{totalAmount}</Text>
// // //      </Flex>
// // //      <Flex justify="space-between">
// // //       <Text fontWeight="bold">Discount ({discountPercentage}%):</Text>
// // //       <Text>- ₹{discountAmount.toFixed(2)}</Text>
// // //      </Flex>
// // //      <Flex justify="space-between" fontWeight="bold" fontSize="lg">
// // //       <Text>Total:</Text>
// // //       <Text>₹{finalAmount.toFixed(2)}</Text>
// // //      </Flex>
// // //      <Button
// // //       colorScheme="red"
// // //       w="100%"
// // //       mt={4}
// // //       size="lg"
// // //       onClick={() => alert("Order placed!")}
// // //      >
// // //       Place Order
// // //      </Button>
// // //     </VStack>
// // //    </Box>
// // //   </Box>
// // //  );
// // // };

// // // export default CartCard;

// // // // import {
// // // //  Box,
// // // //  Image,
// // // //  Text,
// // // //  Button,
// // // //  VStack,
// // // //  HStack,
// // // //  Divider,
// // // //  Heading,
// // // //  Flex,
// // // // } from "@chakra-ui/react";
// // // // import React, { useState } from "react";

// // // // const CartCard = ({ cartItems = [], handleQuantityChange, handleRemove }) => {
// // // //  // State to control the number of items displayed
// // // //  const [visibleItems, setVisibleItems] = useState(2);

// // // //  // Ensure `cartItems` is always an array
// // // //  const items = Array.isArray(cartItems) ? cartItems : [];

// // // //  // Calculate totals
// // // //  const totalAmount = items.reduce(
// // // //   (acc, item) => acc + item.price * (item.quantity || 1),
// // // //   0
// // // //  );

// // // //  const discountPercentage = 10; // Example discount percentage
// // // //  const discountAmount = (totalAmount * discountPercentage) / 100;
// // // //  const finalAmount = totalAmount - discountAmount;

// // // //  // Function to show more items
// // // //  const handleShowMore = () => {
// // // //   setVisibleItems((prev) => prev + 2); // Show 2 more items
// // // //  };

// // // //  return (
// // // //   <Box
// // // //    bg="white"
// // // //    p={6}
// // // //    borderWidth={1}
// // // //    borderRadius="lg"
// // // //    shadow="lg"
// // // //    maxW="lg"
// // // //    mx="auto"
// // // //   >
// // // //    {/* Header */}
// // // //    <Box mb={6}>
// // // //     <Heading as="h3" size="md" textAlign="center">
// // // //      Your Cart
// // // //     </Heading>
// // // //    </Box>

// // // //    {/* Body */}
// // // //    <VStack spacing={4} align="stretch" mb={6}>
// // // //     {items.length === 0 ? (
// // // //      <Text textAlign="center" fontSize="lg" color="gray.500">
// // // //       No items in cart
// // // //      </Text>
// // // //     ) : (
// // // //      items.slice(0, visibleItems).map((item) => (
// // // //       <VStack
// // // //        key={item._id}
// // // //        p={4}
// // // //        borderWidth={1}
// // // //        borderRadius="md"
// // // //        shadow="sm"
// // // //        bg="gray.50"
// // // //       >
// // // //        <HStack spacing={4} w={"100%"}>
// // // //         <VStack align="start" spacing={2} flex="2">
// // // //          <Text fontWeight="bold" fontSize="lg">
// // // //           Product : {item?.productDetails?.productName}
// // // //          </Text>
// // // //          <Text color="gray.600">Price: ₹{item.price}</Text>
// // // //          {item?.type !== "Product" && (
// // // //           <HStack spacing={3} mt={2}>
// // // //            <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
// // // //             -
// // // //            </Button>
// // // //            <Text fontWeight="bold">{item.quantity}</Text>
// // // //            <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
// // // //             +
// // // //            </Button>
// // // //           </HStack>
// // // //          )}
// // // //          {/* <Text>Total: ₹{item.totalPrice}</Text> */}
// // // //          <Button
// // // //           variant="link"
// // // //           colorScheme="red"
// // // //           size="sm"
// // // //           onClick={() => handleRemove(item._id)}
// // // //          >
// // // //           Remove
// // // //          </Button>
// // // //         </VStack>
// // // //         <Image
// // // //          boxSize={"100px"}
// // // //          src={
// // // //           item?.type === "Product"
// // // //            ? item?.productDetails?.productImage
// // // //            : item?.productDetails?.image
// // // //          }
// // // //         />
// // // //        </HStack>
// // // //       </VStack>
// // // //      ))
// // // //     )}
// // // //     {/* Show More Button */}
// // // //     {visibleItems < items.length && (
// // // //      <Button
// // // //       colorScheme="blue"
// // // //       size="sm"
// // // //       variant="outline"
// // // //       onClick={handleShowMore}
// // // //      >
// // // //       Show More
// // // //      </Button>
// // // //     )}
// // // //    </VStack>

// // // //    {/* Footer */}
// // // //    <Box>
// // // //     <Divider mb={4} />
// // // //     <VStack align="stretch" spacing={2}>
// // // //      <Flex justify="space-between">
// // // //       <Text fontWeight="bold">Subtotal:</Text>
// // // //       <Text>₹{totalAmount}</Text>
// // // //      </Flex>
// // // //      <Flex justify="space-between">
// // // //       <Text fontWeight="bold">Discount ({discountPercentage}%):</Text>
// // // //       <Text>- ₹{discountAmount.toFixed(2)}</Text>
// // // //      </Flex>
// // // //      <Flex justify="space-between" fontWeight="bold" fontSize="lg">
// // // //       <Text>Total:</Text>
// // // //       <Text>₹{finalAmount.toFixed(2)}</Text>
// // // //      </Flex>
// // // //      <Button
// // // //       colorScheme="red"
// // // //       w="100%"
// // // //       mt={4}
// // // //       size="lg"
// // // //       onClick={() => alert("Order placed!")}
// // // //      >
// // // //       Place Order
// // // //      </Button>
// // // //     </VStack>
// // // //    </Box>
// // // //   </Box>
// // // //  );
// // // // };

// // // // export default CartCard;

// // // // // // File: src/components/CartCard.jsx

// // // // // import {
// // // // //  Box,
// // // // //  Image,
// // // // //  Text,
// // // // //  Button,
// // // // //  VStack,
// // // // //  HStack,
// // // // //  Divider,
// // // // //  Heading,
// // // // //  Flex,
// // // // // } from "@chakra-ui/react";
// // // // // import React from "react";

// // // // // const CartCard = ({ cartItems = [], handleQuantityChange, handleRemove }) => {
// // // // //  // Ensure `cartItems` is always an array
// // // // //  const items = Array.isArray(cartItems) ? cartItems : [];

// // // // //  // Calculate totals
// // // // //  const totalAmount = items.reduce(
// // // // //   (acc, item) => acc + item.price * (item.quantity || 1),
// // // // //   0
// // // // //  );

// // // // //  const discountPercentage = 10; // Example discount percentage
// // // // //  const discountAmount = (totalAmount * discountPercentage) / 100;
// // // // //  const finalAmount = totalAmount - discountAmount;

// // // // //  return (
// // // // //   <Box
// // // // //    bg="white"
// // // // //    p={6}
// // // // //    borderWidth={1}
// // // // //    borderRadius="lg"
// // // // //    shadow="lg"
// // // // //    maxW="lg"
// // // // //    mx="auto"
// // // // //   >
// // // // //    {/* Header */}
// // // // //    <Box mb={6}>
// // // // //     <Heading as="h3" size="md" textAlign="center">
// // // // //      Your Cart
// // // // //     </Heading>
// // // // //    </Box>

// // // // //    {/* Body */}
// // // // //    <VStack spacing={4} align="stretch" mb={6}>
// // // // //     {items.length === 0 ? (
// // // // //      <Text textAlign="center" fontSize="lg" color="gray.500">
// // // // //       No items in cart
// // // // //      </Text>
// // // // //     ) : (
// // // // //      items.map((item) => (
// // // // //       <Box
// // // // //        key={item._id}
// // // // //        p={4}
// // // // //        borderWidth={1}
// // // // //        borderRadius="md"
// // // // //        shadow="sm"
// // // // //        bg="gray.50"
// // // // //       >
// // // // //        <HStack spacing={4}>
// // // // //         <VStack align="start" spacing={2} flex="1">
// // // // //          <Text fontWeight="bold" fontSize="lg">
// // // // //           Product : {item?.productDetails?.productName}
// // // // //          </Text>
// // // // //          <Text color="gray.600">Price: ₹{item.price}</Text>
// // // // //          {item?.type !== "Product" && (
// // // // //           <HStack spacing={3} mt={2}>
// // // // //            <Button size="sm" onClick={() => handleQuantityChange(item._id, -1)}>
// // // // //             -
// // // // //            </Button>
// // // // //            <Text fontWeight="bold">{item.quantity}</Text>
// // // // //            <Button size="sm" onClick={() => handleQuantityChange(item._id, 1)}>
// // // // //             +
// // // // //            </Button>
// // // // //           </HStack>
// // // // //          )}
// // // // //          <Text>Total: ₹{item.totalPrice}</Text>
// // // // //          <Button
// // // // //           variant="link"
// // // // //           colorScheme="red"
// // // // //           size="sm"
// // // // //           onClick={() => handleRemove(item._id)}
// // // // //          >
// // // // //           Remove
// // // // //          </Button>
// // // // //         </VStack>
// // // // //        </HStack>
// // // // //       </Box>
// // // // //      ))
// // // // //     )}
// // // // //    </VStack>

// // // // //    {/* Footer */}
// // // // //    <Box>
// // // // //     <Divider mb={4} />
// // // // //     <VStack align="stretch" spacing={2}>
// // // // //      <Flex justify="space-between">
// // // // //       <Text fontWeight="bold">Subtotal:</Text>
// // // // //       <Text>₹{totalAmount}</Text>
// // // // //      </Flex>
// // // // //      <Flex justify="space-between">
// // // // //       <Text fontWeight="bold">Discount ({discountPercentage}%):</Text>
// // // // //       <Text>- ₹{discountAmount.toFixed(2)}</Text>
// // // // //      </Flex>
// // // // //      <Flex justify="space-between" fontWeight="bold" fontSize="lg">
// // // // //       <Text>Total:</Text>
// // // // //       <Text>₹{finalAmount.toFixed(2)}</Text>
// // // // //      </Flex>
// // // // //      <Button
// // // // //       colorScheme="red"
// // // // //       w="100%"
// // // // //       mt={4}
// // // // //       size="lg"
// // // // //       onClick={() => alert("Order placed!")}
// // // // //      >
// // // // //       Place Order
// // // // //      </Button>
// // // // //     </VStack>
// // // // //    </Box>
// // // // //   </Box>
// // // // //  );
// // // // // };

// // // // // export default CartCard;
