import React, { useEffect, useState } from "react";
import {
 Box,
 Heading,
 Text,
 Input,
 VStack,
 HStack,
 Button,
 Divider,
 RadioGroup,
 Radio,
 Icon,
 Stack,
} from "@chakra-ui/react";
import { FiMapPin } from "react-icons/fi";
import { useSelector, useDispatch } from "react-redux";
// import { fetchUserAddresses, createUserAddress } from "../../redux/cartSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { fetchUserAddresses } from "../../redux/cartSlice";

const AddressForm = () => {
 const dispatch = useDispatch();
 const navigate = useNavigate();

 // Example: user is stored in auth slice
 const { user } = useSelector((state) => state.auth);
 const userId = user._id; // e.g. "66e723460a6928913c986363"

 // Pull savedAddresses, loading, error from Redux cart slice
 const { savedAddresses, loading, error } = useSelector((state) => state.cart);

 // Local state for new address input
 // Make sure to collect the full fields (matching your backend)
 const [newAddress, setNewAddress] = useState({
  name: "",
  houseFlatNumber: "",
  completeAddress: "",
  pincode: "",
  landmark: "",
 });

 // Selected existing address _id
 const [selectedAddressId, setSelectedAddressId] = useState("");

 // Fetch addresses on mount (or userId change)
 useEffect(() => {
  if (userId) {
   dispatch(fetchUserAddresses(userId));
  }
 }, [userId, dispatch]);

 // Handle typed input for new address
 const handleAddressChange = (e) => {
  const { name, value } = e.target;
  setNewAddress((prev) => ({
   ...prev,
   [name]: value,
  }));
 };

 // On “Continue” click
 const handleContinue = async () => {
  if (selectedAddressId) {
   // The user selected an existing address
   const addressToUse = {
    addressId: selectedAddressId,
    type: "EXISTING",
   };
   // Navigate to /cart-summary, passing the address choice
   navigate("/cart-summary", {
    state: { address: addressToUse || newAddress },
   });
  } else {
   // The user typed a new address
   try {
    const addressToUse = {
     addressId: selectedAddressId,
     type: "EXISTING",
    };
    navigate("/cart-summary", {
     state: { address: newAddress },
    });
    // 1) Dispatch the createUserAddress thunk
    // const resultAction = await dispatch();
    //  createUserAddress({ userId, newAddress })
    // if (createUserAddress.fulfilled.match(resultAction)) {
    //  // The newly created address is in resultAction.payload
    //  const createdAddress = resultAction.payload;
    //  console.log("New address created:", createdAddress);
    //  const addressToUse = {
    //   ...createdAddress, // or just _id if you want
    //   type: "NEW",
    //  };
    //  // 2) Navigate to /cart-summary with the new address
    //  navigate("/cart-summary", { state: { address: addressToUse } });
    // } else {
    //  // If there's an error, you can show an alert or handle it
    //  console.error("Error creating address:", resultAction.payload);
    //  alert("Error creating address. Please try again.");
    // }
   } catch (err) {
    console.error("Thunk error:", err);
   }
  }
 };

 return (
  <Box
   maxW="600px"
   mx="auto"
   mt={8}
   p={6}
   borderWidth={1}
   borderRadius="md"
   bg="white"
  >
   <Heading as="h2" size="lg" mb={4}>
    Select / Add Address
   </Heading>
   <Text fontSize="sm" color="gray.600" mb={6}>
    Select from saved addresses or add a new address
   </Text>

   {/* Loading & Error handling */}
   {loading && <Text>Loading saved addresses...</Text>}
   {error && <Text color="red.500">Error: {error}</Text>}

   {/* New Address Form */}
   <VStack spacing={4} mb={6}>
    <Input
     placeholder="Full Name"
     name="name"
     value={newAddress.name}
     onChange={handleAddressChange}
    />
    <Input
     placeholder="House/Flat Number"
     name="houseFlatNumber"
     value={newAddress.houseFlatNumber}
     onChange={handleAddressChange}
    />
    <Input
     placeholder="Complete Address"
     name="completeAddress"
     value={newAddress.completeAddress}
     onChange={handleAddressChange}
    />
    <Input
     placeholder="Pincode"
     name="pincode"
     value={newAddress.pincode}
     onChange={handleAddressChange}
    />
    <Input
     placeholder="Landmark"
     name="landmark"
     value={newAddress.landmark}
     onChange={handleAddressChange}
    />
   </VStack>

   <Divider mb={6} />

   {/* Saved Addresses */}
   <Heading as="h3" size="md" mb={4}>
    Choose from Saved Addresses
   </Heading>

   <RadioGroup
    value={selectedAddressId}
    onChange={(value) => setSelectedAddressId(value)}
   >
    <VStack spacing={4} align="stretch">
     {/* Map over saved addresses from Redux */}
     {savedAddresses?.map((addr) => (
      <Box
       key={addr._id}
       p={4}
       borderWidth={1}
       borderRadius="md"
       bg={selectedAddressId === addr._id ? "blue.50" : "gray.50"}
       borderColor={selectedAddressId === addr._id ? "blue.500" : "gray.200"}
      >
       <HStack justify="space-between" align="center">
        <HStack>
         <Icon as={FiMapPin} color="blue.500" boxSize={6} />
         <Text fontSize="sm" color="gray.700">
          {/* Display relevant address info */}
          {addr.name}, {addr.houseFlatNumber}, {addr.completeAddress}, Pincode:{" "}
          {addr.pincode}
         </Text>
        </HStack>
        <Radio value={addr._id} />
       </HStack>
      </Box>
     ))}
    </VStack>
   </RadioGroup>

   {/* Footer Button */}
   <Button colorScheme="red" w="100%" mt={6} onClick={handleContinue}>
    Continue →
   </Button>
  </Box>
 );
};

export default AddressForm;
